// IMPORT
// ----------------------------------
import {WebAuth} from 'auth0-js';
import constants from '../../constants.js';
const axios = require('axios');
import jwtDecode from 'jwt-decode';

// EXPORT METHOD
// ----------------------------------
function createModule({domain, clientID}) {
  const webAuth = new WebAuth({
    domain,
    clientID,
    // This is required for renewAuth to have an appropriate idToken.
    responseType: 'token id_token',
  });

  let localStorageItems = ['access_token', 'id_token', 'sidebarSecondary', 'user', 'zs_groups', 'zs_sidebar', 'zs_sidebar_expire'];

  return {
    namespaced: true,
    state: {
      lock: null,
      webAuth,
      idToken: null,
      authToken: null,
      /*
      Pre-emptive user information, stored on the token.
      This should only be used for initial load and not granular level state.
      */
      parsedToken: null,
      setAuthIsAuthenticatingLocalStorage,
      setAuthIsAuthenticatingLocalStorageObserver,
      setAuthIsNewSignupLocalStorage,
      // Popup authenitcation confirmatin
      popupAuth: false,
      popupAuthSignup: false,
    },
    getters: {
      is_social: (state) => {
        if(state.parsedToken) {
          return state.parsedToken.sub.indexOf('auth0') === -1;
        }
        return false;
      },
      user_id: (state) => {
        return (state.parsedToken) ? state.parsedToken.sub : null;
      },
      email: (state) => {
        return (state.parsedToken) ? state.parsedToken.email: null;
      },
    },
    mutations: {
      init_lock(state, open) {
        state.lock = new Auth0Lock(
          constants.AUTH_CLIENT_ID,
          constants.AUTH_DOMAIN,
          {
            configurationBaseUrl: 'https://cdn.auth0.com',
            allowedConnections: ['google-oauth2', 'Username-Password-Authentication'],
            allowSignUp: true,
            rememberLastLogin: false,
            theme: {
              primaryColor: '#04a3f5',
              logo: '/assets/images/zingsoft.png',
            },
            closable: true,
            languageDictionary: {
              title: '',
              signupTitle: '',
            },
            // socialButtonStyle: 'small',
            auth: {
              sso: false,
              // Add in openid profile to the JWT.
              // Required since we want to reduce querying user data on the serverside.
              params: {scope: 'openid profile email user_metadata app_metadata picture'},
              responseType: 'id_token',
              redirect: true,
              redirectUrl: window.location.origin,
            },
            hashCleanup: true,
          }
        );

        if (open) {
          state.lock.show({
            initialScreen: 'signup',
          });
        }
      },
      /**
       * @description When login or signup is in limbo state, where user views dashboard page when no user info is
       * loaded, log user out
       * @param { Object } state
       */
      unsuccessful(state) {
        state.user = state.idToken = state.accessToken = null;
        localStorageItems.forEach((token) => {
          localStorage.removeItem(token);
        });
        window.location.href = '/unsuccessful';
      },
      logout(state) {
        // Clear localstorage
        state.user = state.idToken = state.accessToken = null;
        localStorageItems.forEach((token) => {
          localStorage.removeItem(token);
        });
        // Clear state
        this.commit('user/clear');
        // Log off on Beacon
        if (typeof Beacon !== 'undefined') {
          // clear chat widget
          Beacon('logout');
        };
        // Destroy sessions
        axios({
          url: '/api/user/serversession',
          method: 'POST',
          data: {'destroy': true},
        });
      },
      add_tokens(state, tokens) {
        state.idToken = true;
        if (tokens.idToken && tokens.idToken !== true) {
          let parsedToken = jwtDecode(tokens.idToken);
          state.parsedToken = {
            sub: parsedToken.sub.slice(0, 6) + 'secret',
            iat: parsedToken.iat,
          };
        }
      },
    },
    actions: {
      authorize_google(context, data) {
        webAuth.authorize({
          connection: 'google-oauth2',
          params: {scope: 'openid profile email user_metadata app_metadata picture'},
          prompt: 'login',
          responseType: 'id_token',
          redirect: true,
          redirectUri: window.location.origin,
        }, (err) => {
          if (err) {
            this.commit('ui/sendMessage', err.description);
          }

          if (authResult) {
            if (data.regenerateSession) {
              // Adds token to session
              axios({
                url: '/api/user/serversession',
                method: 'POST',
                headers: { 'Authorization': `Bearer ${authResult.idToken}` },
                data: { 'regenerate': true },
              });
            };
          }
          return;
        });
      },
      /* NOTE: After Cookie SameSite updates, unable to test this within iframe because context is non-secure (http and not https).
       * Test ZingChart/ZingGrid site and Studio app in one of the secure context:
       * 1. Push changes to staging site and test there (hard to debug)
       * 2. Run dev sites locally and use localtunnel to open HTTPS tunnel to point to specified port(s).
       *    Use the URLS return by command.
       *    https://localtunnel.github.io/www/
       *    Steps:
       *      a. Open Studio dev server: Click debug button or npm run dev
       *      b. Open tunnel to Studio: lt --port 8090 --subdomain zingsoft
       *      c. Open site dev derver: npm run dev:prod
       *      d. Open tunnel to site: lt --port ####
       *      e. Test subscription login on url provided in step e
       */
      authorize_google_popup(context, data) {
        webAuth.popup.authorize({
          connection: 'google-oauth2',
          prompt: 'login',
          responseType: 'id_token',
          redirectUri: `${window.location.origin}/popup-handler`,
        }, (err, authResult) => {
          
          // Error
          if (err && err.description) {
            this.commit('ui/sendMessage', err.description);
          }

          // Success => Move token to localstorage (authenticates user) and boot app
          if (authResult) {
            localStorage.setItem('id_token', true);
            this.commit('auth/add_tokens', {
              idToken: authResult.idToken,
            });
            if (this.state.auth.parsedToken && this.state.auth.parsedToken.sub.indexOf('auth0|') > -1) {
              // Google Auth Login
              this.dispatch('refresh_state', { idToken: `Bearer ${authResult.idToken}` });
              this.state.auth.popupAuth = true;
            } else {
              // Google Auth Signup
              this.state.auth.popupAuthSignup = true;
            };

            axios({
              url: '/api/user/serversession',
              method: 'POST',
              headers: { 'Authorization': `Bearer ${authResult.idToken}` },
              data: data.regenerateSession ? { 'regenerate': true } : {},
            });
          }
          return;
        });
      },
      changePassword(context, data) {
        webAuth.changePassword({
          connection: 'Username-Password-Authentication',
          email: data.email,
        }, (err) => {
          if(err) {
            this.commit('ui/sendMessage', err.message);
          }

          return;
        });
      },
      login(context, data) {
        webAuth.login({
          realm: 'Username-Password-Authentication',
          email: data.email,
          password: data.password,
          responseType: 'id_token',
          redirectUri: window.location.origin,
        }, (err, authResult) => {
          if (err) {
            this.commit('ui/sendMessage', err.description);
          };

          if (data.regenerateSession) {
            axios({
              url: '/api/user/serversession',
              method: 'POST',
              headers: { 'Authorization': `Bearer ${authResult.idToken}` },
              data: { 'regenerate': true },
            });
          };
          return;
        });
      },
      signup(context, data) {
        webAuth.signup({
          connection: 'Username-Password-Authentication',
          email: data.email,
          password: data.password,
        }, (err) => {
          if (err) {
            let message = err.name === 'PasswordStrengthError' ? 'Passworld is too weak' : err.description;
            if (message !== 'The user already exists.') {
              this.commit('ui/sendMessage', message);

              return;
            }
          }

          // now try and login the user to Auth0
          // if successful we can redirect to app login
          webAuth.redirect.loginWithCredentials({
            connection: 'Username-Password-Authentication',
            email: data.email,
            password: data.password,
            responseType: 'id_token',
            redirectUri: window.location.origin,
          }, (err) => {
            if (err) {
              if (err.error_description === 'Wrong email or password.') {
                this.commit('ui/sendMessage', 'Email already registered, please login or sign up with an unregistered email.');
              } else this.commit('ui/sendMessage', err.error_description);
            }
            return;
          });
        });
      },
      // Signs up user with autogenerated password
      // (without logging user in and redirecting)
      // and sends password reset to email used for signup
      adminSignup(context, data) {
        // Sign up user
        webAuth.signup({
          connection: 'Username-Password-Authentication',
          email: data.email,
          password: data.password
        }, (err, authResult) => {
          if (err) {
            let message = err.name === 'PasswordStrengthError' ? 'Passworld is too weak' : err.description;
            if (message !== 'The user already exists.') {
              this.commit('ui/sendMessage', message);
              return;
            }
          } else {
            // After successfully creating account, reset password
            webAuth.changePassword({
              connection: 'Username-Password-Authentication',
              email: data.email,
            }, (err) => {
              if(err) {
                this.commit('ui/sendMessage', err.message);
              }
            });
          };
        });
      },
    },
  };
}

// EXPORT
// ----------------------------------
export default createModule;


// HELPER METHODS / GETTERS
// ----------------------------------

/**
 * @description If not toggling auth state on a 3rd-party, dynamically generated element
 * and instead just want to set the 'is authenticating' localStorage var directly.
 * See: `loginGoogle()` in `Login.vue`. When clicking that button it goes directly to the
 * Auth0 auth page instead of launching the Auth0 modal overlay.
 */
function setAuthIsAuthenticatingLocalStorage() {
  localStorage.setItem('isAuthenticating', true);
}

/**
 * @description used to trigger events based on new signup in appshell
 */
function setAuthIsNewSignupLocalStorage() {
  localStorage.setItem('setupBeacon', true);
  localStorage.setItem('setupDemos', true);
}

/**
 * @description Set auth localStorage var based off the state of the target element
 * @example For the Auth0 overlay modal, this is dynamically added to the DOM and the api
 * doesn't provide events to listen for. So, this method attaches a Mutation Observer to the element
 * so we can set an authentication state based on whether the modal is open or not.
 * See: `launch()` in `SecondaryNav.vue`
 */
function setAuthIsAuthenticatingLocalStorageObserver() {
  // Store Auth0's dynamically added DOM widget. We'll observe this element.
  let $auth0Container = document.querySelector('#auth0-lock-container-1');
  // Setup Mutation Observer/Local Storage items
  let authState, count = 0, eventCount = [];

  // Capture 'is authenticating' state
  // NOTE: We're doing this observer process so that when the user
  // is redirected back to the app from Auth0, we'll read the localStorage
  // variable (because it will be immediately available) instead of waiting
  // for `this.authenticated` to be set in AppShell.vue, which has to wait
  // for the Vuex store to be init and then the store mutation to fire, etc.
  // ---
  // Before this system, the login page showed briefly before the Vue app
  // could process the logic that you were authenticated to redirect you
  // to the dashboard view. This way, we immediately read the localStorage var
  // and load the dashboard or login view directly instead of loading the
  // login view and then redirecting.
  // ---
  // Due to the nature of 'childList' mutation events, an event is fired
  // when the element is both added and removed from the DOM. To only listen
  // to one of those events (so we don't overwrite the newly added localStorage var),
  // We added the `eventCount` array so we can count the targeted event.
  // ---
  // On launch, set authenticating to TRUE
  localStorage.setItem('isAuthenticating', true);
  // Init Mutation Observer
  const observerConfig = { childList: true };
  const callback = function(mutationsList, observer) {
    // We only want to do stuff on the first observed event
    eventCount.push(count++);
    if (eventCount.length > 1) return;
    // Mutation events list
    for(let mutation of mutationsList) {
      // Toggle 'is authenticating' state
      authState = localStorage.getItem('isAuthenticating') == 'true';
      // !authState = if true, set false. If false, set true.
      localStorage.setItem('isAuthenticating', !authState);
    }
  };
  const observer = new MutationObserver(callback);
  observer.observe($auth0Container, observerConfig);
  // observer.disconnect();
}
