<template>
  <el-dialog
    title="Settings"
    v-model="visibility"
    class="settings__dialog"
    :before-close="handleClose">
      <el-tabs :tabPosition="isMobile ? 'top' : 'left'" @tab-click="currentTab">
        <!-- GENERAL -->
        <el-tab-pane v-if="showSettings('general')" label="General">
          <el-row>
            <el-col :span="18" style="border-right:1px solid var(--border-light)">
              <section class="settings__entry">
                <label>Title</label>
                <div style="display: flex;">
                  <el-input placeholder="Untitled Demo" type="text" v-model="title" size="small" maxlength="75"/>
                </div>
              </section>
              <section class="settings__entry">
                <label>Description</label>
                <div>
                  <el-input class="demo-description" placeholder="Demo Description" type="textarea" autosize v-model="description" :rows="10" maxlength="250"/>
                </div>
              </section>

              <section class="settings__entry">
                <label>Tags</label>
                <div>
                  <el-tag
                    :key="tag.name"
                    v-for="tag in tags"
                    closable
                    :disable-transitions="false"
                    @close="removeTag(tag.id, tag.name)">
                    {{tag.name}}
                  </el-tag>
                  <template v-if="inputVisible">
                    <!-- ADMIN TAG INPUT -->
                    <el-select
                      v-show="adminTagAccess"
                      class="input-new-tag"
                      allow-create
                      filterable
                      v-model="inputValue"
                      @change="addTag"
                      @keyup.enter="addTag"
                      style="max-width:150px;">
                      <el-option
                        v-for="(tag, index) in adminTagOptions"
                        :key="index"
                        :label="tag"
                        :value="tag">
                      </el-option>
                    </el-select>
                  </template>
                  <el-button v-else class="button-new-tag el-tag" size="small" @click="showInput">+ New Tag</el-button>
                </div>
              </section>

              <section class="settings__entry">
                <el-button type="danger" @click="deleteDemo" :disabled="demo && !demo.uid" size="small">Delete Demo</el-button>
              </section>
            </el-col>
            
            <el-col :offset="2" :span="4">
              <section v-show="adminTemplateCreate" class="settings__entry">
                <label>Public</label>
                <br/>
                <el-switch v-model="isPublic">
                </el-switch>
              </section>
              <section class="settings__entry">
                <label>Run On Click</label>
                <br/>
                <el-switch v-model="runOnClick">
                </el-switch>
              </section>
            </el-col>
          </el-row>

          <el-row>
            <section class="settings__entry" style="display:flex; justify-content: flex-end; align-items: flex-end; flex:1;">
              <el-button @click="emit('toggle-visibility')">Close</el-button>
              <el-button type="primary" @click="saveData">Save</el-button>
            </section>
          </el-row>
        </el-tab-pane>

        <!-- UPLOAD FILES -->
        <el-tab-pane label="Files" v-if="showSettings('upload')" >
          <header><h3 class="upload-dialog__heading">Uploaded Files</h3></header>
          <ul class="upload-dialog__files">
            <li v-show="files.length === 0">No files uploaded</li>
            <li class="upload-dialog__file" v-for="(file, index) in files" :key="index">
              <div v-if="fileThumbnailStyle(file)" class="upload-dialog__file__thumbnail--image" :style="fileThumbnailStyle(file)"></div>
              <svg-icon v-else icon="file" height="28" class="upload-dialog__file__thumbnail--text"></svg-icon>
              <input type="text" :value="file" style="width: 100%"/>
              <div style="display: flex">
                <el-button size="small" @click="copyLink(file)" plain>copy link</el-button>
                <el-button size="small" type="danger" @click="deleteFile(file)" plain>delete</el-button>
              </div>
            </li>
          </ul>

          <section>
            <header><h3 class="upload-dialog__heading">Upload Files</h3></header>
            <el-button type="primary" @click="openFilestack">Upload</el-button>
            <p>Maximum upload size is 5MB</p>
            <p>Accepted filetypes are limited to image and text.</p>
            <p>To set image as thumbnail:</p>
            <ul>
              <li>Find an image you want to set as the demo thumbnail</li>
              <li>Rename selected image to "demo_thumbnail" (ex. demo_thumbnail.png)</li>
              <li>Upload the image (Name of file will be "DEMO_ID/RANDOM_HASH_demo_thumbnail.png")</li>
              <li>Save demo</li>
            </ul>
          </section>

          <template v-slot:footer>
            <span class="dialog-footer">
              <el-button @click="$emit('toggle-visibility')">Close</el-button>
            </span>
          </template>
        </el-tab-pane>

        <!-- SHARE -->
        <el-tab-pane label="Share" v-if="showSettings('share')" >
            <h4 style="color:#dc1257" v-show="demo && !demo.uid">
              Save the demo before sharing!
            </h4>
            <section style="margin-bottom: 1rem;">
              <header>
                <h3>Share Link</h3>
                <div class="demo-control__item">
                  <el-select v-model="shareValue" placeholder="Select" size="small">
                    <el-option
                      v-for="item in shareOptions"
                      :key="item.id"
                      :label="item.label"
                      :value="item.id">
                    </el-option>
                  </el-select>
                  <svg-icon class="el-input-icon" icon="angle"></svg-icon>
                </div>
              </header>
              <el-input v-model="shareLink" :disabled="demo && !demo.uid">
                <template v-slot:append>
                  <el-button @click="copyShareLink" :disabled="demo && !demo.uid">COPY</el-button>
                </template>
              </el-input>
            </section>
          <section>
            <h3>Download the demo as an HTML file</h3>
            <el-button type="primary" @click="download" size="small" :disabled="demo && !demo.uid"><font-awesome-icon :icon="['fas', 'download']"/> DOWNLOAD</el-button>
          </section>
        </el-tab-pane>

        <!-- EDITOR -->
        <el-tab-pane label="Editor" v-if="showSettings('editor')" >
          <section style="margin-bottom: 1rem;">
            <h3>Auto-update preview</h3>
            OFF <el-switch v-model="autoUpdate" @change="updateAutoUpdateSettings"/> ON
          </section>

          <section v-show="hide" style="margin-bottom: 1rem;">
            <h3>Documentation Hints</h3>
            OFF <el-switch v-model="docsTooltip" @change="updateDocsToolipsSettings"/> ON
          </section>

          <section style ="margin-bottom: 1rem;">
            <h3>Auto-complete</h3>
            <el-tree
              @check-change="updateAutoCompleteSettings"
              :data="autoCompleteTree"
              :default-checked-keys="autoComplete"
              default-expand-all
              node-key="id"
              show-checkbox
              :props="defaultAutoCompleteProps">
            </el-tree>
          </section>
        </el-tab-pane>

        <!-- TEMPLATE -->
        <el-tab-pane label="Template" v-if="showSettings('template')" >
          <section class="settings__entry">
            <h3>Template</h3>
            <div><el-switch v-model="isTemplate"></el-switch> Mark as template</div>

            <h3>Template Type</h3>
            <div class="demo-control__item">
              <el-select v-model="demoTemplate" placeholder="Select">
                <el-option
                  v-for="item in templateTypes"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id">
                </el-option>
              </el-select>
              <svg-icon class="el-input-icon" icon="angle"></svg-icon>
            </div>
          </section>

          <section v-show="premiumContentSet || adminTemplateCreate" class="settings__entry">
            <h3>Admin Settings</h3>
            <div v-show="premiumContentSet">
              <el-switch v-model="isPremium"></el-switch> Premium (Only premium users can view)
            </div>
            <div v-show="adminTemplateCreate">
              <el-switch v-model="isPublic"></el-switch> Public (Mark both "Template" and "Public" creates a template for all users)
            </div>
          </section>

          <section class="settings__entry" style="display:flex; justify-content: flex-end; align-items: flex-end; flex:1;">
            <el-button @click="emit('toggle-visibility')">Close</el-button>
            <el-button type="primary" @click="saveData">Save</el-button>
          </section>

        </el-tab-pane>

        <!-- METADATA -->
        <el-tab-pane label="Metadata" v-if="showSettings('metadata') && adminMetadataUpdate">
          <section class="settings__entry" style="margin-bottom: 1rem;">
            <h3>Metadata</h3>
            <div v-show="adminMetadataUpdate">
              <p>To include demo in gallery, add the following</p>
              <ul>
                <li>"zc-gallery" or "zg-gallery" tag (in "General" tab)</li>
                <li>"zingchart.com-demo" or "zinggrid.com-demo" tag (in "General" tab)</li>
                <li>"vanityUrl" metadata</li>
                <li>"feature" metadata</li>
              </ul>
            </div>
            <p v-show="!metadata && !insertingMetadata">Demo has no metadata.</p>
            <div demo-settings="metadata" ref="demoSettingsMetadata"></div>
            <el-button demo-settings="metadata__add--gallery" @click="galleryMetadataVisible=true">Add Gallery Metadata</el-button>
          </section>

          <section class="settings__entry" style="display:flex; justify-content: flex-end; align-items: flex-end; flex:1;">
            <el-button @click="emit('toggle-visibility')">Close</el-button>
            <el-button type="primary" @click="saveData">Save</el-button>
          </section>
        </el-tab-pane>

      </el-tabs>
    <textarea ref="copyBuffer" style="height: 0; width:0; opacity:0;"></textarea>

    <gallery-metadata
      @close="galleryMetadataVisible=false"
      @open="galleryMetadataVisible=true"
      :zgRef="zgRef"
      :demoType="demo.type"
      :metadata="demo.metadata"
      v-model="galleryMetadataVisible"
    ></gallery-metadata>

  </el-dialog>
</template>

<script setup>
  import { computed, defineEmits, defineProps, getCurrentInstance, nextTick, onBeforeMount, onUnmounted, ref, watch } from 'vue';
  import { computedAsync } from '@vueuse/core';
  import { useRoute, useRouter } from 'vue-router';
  import { useStore } from 'vuex';
  import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
  import GalleryMetadata from './components/GalleryMetadata.vue';
  import SvgIcon from '../../components/SvgIcon.vue';
  import permissionsComposable from '../../mixins/permissions';

  const { checkPermission } = permissionsComposable();

  const emit = defineEmits(['keyup', 'update-demo', 'toggle-visibility', 'save']);

  const props = defineProps({
    demo: Object,
    settings: Array,
    settingsVisible: Boolean,
  });

  const instance = getCurrentInstance();
  const $global = instance.appContext.config.globalProperties;
  const $api = $global.$api;
  const $confirm = $global.$confirm;
  const $message = $global.$message;
  const $route = useRoute();
  const $router = useRouter();
  const $store = useStore();
  const MEGABYTE = ref(1048576);
  const $content = ref(null);
  const $nav = ref(null);
  const zgRef = ref(null);
  const $zgInsertBtn = ref(null);
  const acceptedFiletypes = ref(['.csv', '.tsv', '.xls', 'xslx', '.json', '.gif', '.png', '.jpg', '.jpeg', '.svg', '.webp']);
  const adminTagOptions = ref(['zc-gallery', 'zinggrid.com-demos', 'zinggrid.com-preload', 'zg-gallery']);
  const autoCompleteTree = ref([{
    label: 'All',
    children: [{
      id: 'html',
      label: "HTML",
    }, {
      id: 'css',
      label: "CSS",
    }, {
      id: 'js',
      label: "JavaScript",
    }],
  }]);
  const defaultAutoCompleteProps = ref({
    children: 'children',
    label: 'label'
  });
  const filestackClient = ref(null);
  const hide = ref(false);
  const inputValue = ref('');
  const inputVisible = ref(false);
  const insertingMetadata = ref(false);
  const shareValue = ref('view');
  const shareOptions = ref([{
      id: 'view',
      label: 'Embed / Preview',
    }, {
      id: 'embed',
      label: 'Embed with Code',
    }, {
      id: 'oembed',
      label: 'oEmbed',
    }, {
      id: 'create',
      label: 'Edit'
    }, 
  ]);
  const templateTypes = ref([{
    id: 'basic',
    label: 'Basic Grid',
  }, {
    id: 'blank',
    label: 'Blank Grid',
  }, {
    id: 'custom',
    label: 'Grid with Custom Rows',
  }, {
    id: 'features',
    label: 'Grid with Features',
  }, {
    id: 'headers',
    label: 'Grid with Headers',
  }, {
    id: 'headers_custom',
    label: 'Grid with Headers and Custom Rows',
  }]);
  const uploadForm = ref(new FormData());
  const galleryMetadataVisible = ref(false);
  const copyBuffer = ref(null);
  const saveTagInput = ref(null);
  const demoSettingsMetadata = ref(null);
  
  const adminMetadataUpdate = computedAsync(
    async () => {
      return await checkPermission('admin_metadata_update', null, null, $store);
    }, null
  );
  const adminSaveOverride = computedAsync(
    async () => {
      return await checkPermission('admin_save_override', null, null, $store);
    }, null
  );
  const adminTagAccess = computedAsync(
    async () => {
      return await checkPermission('admin_tag_access', null, null, $store);
    }, null
  );
  const adminTemplateCreate = computedAsync(
    async () => {
      return await checkPermission('admin_template_create', null, null, $store);
    }, null
  );
  const premiumContentSet = computedAsync(
    async () => {
      return await checkPermission('premium_content_set', null, null, $store);
    }, null
  );
  
  // Demo-related
  const description = computed({
    get: function () { return props.demo ? props.demo.description : null; },
    set: function (val) { emit('update-demo', 'description', val); },
  });
  const demoTemplate = computed({ 
    get: function () { return props.demo ? props.demo.demoTemplate : null; },
    set: function (val) { emit('update-demo', 'demoTemplate', val); },
  });
  const files = computed({ 
    get: function () { return props.demo ? props.demo.files : null; },
    set: function (val) { emit('update-demo', 'files', val); },
  });
  const isMobile = computed(() => { 
    return $store.getters['ui/isMobile'];
  });
  const isPremium = computed({ 
    get: function () { return props.demo ? props.demo.isPremium : null; },
    set: function (val) { emit('update-demo', 'isPremium', val); },
  });
  const isPublic = computed({ 
    get: function () { return props.demo ? props.demo.isPublic : null; },
    set: function (val) { emit('update-demo', 'isPublic', val); },
  });
  const isTemplate = computed({ 
    get: function () { return props.demo ? props.demo.isTemplate : null; },
    set: function (val) { emit('update-demo', 'isTemplate', val); },
  });
  const metadata = computed({ 
    get: function () { return props.demo ? props.demo.metadata : null; },
    set: function (val) { emit('update-demo', 'metadata', val); },
  });
  const publish = computed({ 
    get: function () { return props.demo ? props.demo.publish : null; },
    set: function (val) { emit('update-demo', 'publish', val); },
  });
  const tags = computed({ 
    get: function () { return props.demo ? props.demo.tags : null; },
    set: function (val) { emit('update-demo', 'tags', val); },
  });
  const title = computed({ 
    get: function () { return props.demo ? props.demo.title : null; },
    set: function (val) { emit('update-demo', 'title', val); },
  });
  const runOnClick = computed({ 
    get: function () { return props.demo ? props.demo.runOnClick : null; },
    set: function (val) { emit('update-demo', 'runOnClick', val); },
  });
  const type = computed({ 
    get: function () { return props.demo ? props.demo.type : null; },
    set: function (val) { emit('update-demo', 'type', val); },
  });

  // General
  const autoComplete = computed({ 
    get: function () { return props.demo ? props.demo.autoComplete : null; },
    set: function (val) { emit('update-demo', 'autoComplete', val); },
  });
  const autoUpdate = computed({ 
    get: function () { return props.demo ? props.demo.autoUpdate : null; },
    set: function (val) { emit('update-demo', 'autoUpdate', val); },
  });
  const docsTooltip = computed({ 
    get: function () { return props.demo ? props.demo.docsTooltip : null; },
    set: function (val) { emit('update-demo', 'docsTooltip', val); },
  });
  /**
   * @description Sets up Filestack client with options.
   * More on Filestack options are found at https://www.filestack.com/docs/concepts/pickers/web/
   */
  const filestackOptions = computed(() => { 
    return {
      accept: ['application/json', 'image/*', 'text/*'],
      fromSources: ['local_file_system', 'url', 'imagesearch', 'facebook', 'instagram', 'googledrive'],
      maxSize: 5 * MEGABYTE.value,
      storeTo: {
        location: 'gcs',
        container: VUE_APP_CLOUD_ASSETS_BUCKET,
        path: `/${props.demo.uid}/`,
      },
      onOpen: () => {
        setZIndex(true);
      },
      onUploadDone: files => {
        let curLength = files.value.length;
        let expectedLength = curLength + files.filesUploaded.length - files.filesFailed.length;
        getFiles(expectedLength);
      },
      onClose: () => {
        setZIndex(false);
      }
    }
  });
  const shareLink = computed(() => { 
    let sharePath = shareValue.value;
    let shareUrl = `${window.location.origin}/demos/${sharePath}/${props.demo.uid}`;

    // Share link for special case: oembed
    if (sharePath === 'oembed') {
      sharePath = 'view';
      shareUrl = `${window.location.origin}/oembed?url=${window.location.origin}/demos/${sharePath}/${props.demo.uid}`
    };
    return shareUrl;
  });
  const visibility = computed(() => {
    return props.settingsVisible;
  });
  
  watch(() => props.demo, (newValue, oldValue) => {
    if (!zgRef.value && newValue.uid !== oldValue.uid) 
    setupZingGrid();
  }, { deep: true });

  onBeforeMount(() => {
    let interval = setInterval(() => {
      // Early exit if 'filestack' is not defined
      if (typeof (filestack) === 'undefined') return;

      // When defined, initialize filestack client
      filestackClient.value = filestack.init(VUE_APP_FILESTACK_API);
      clearInterval(interval);
    }, 500);
  });

  onUnmounted(() => {
    zgRef.value = null;
  });

  /**
   * @description Add metadata from ZingGrid into variable saved into database.
   * Before adding to the database, make sure that the demo has been created or forked.
   * If the demo has not bee created, there is no id_user or uid associated.
   * If the demo has not been forked yet, the metadata will be added to the original demo and not the forked one.
   */
  function addMetadataRecord(record) {
    // Request to create metadata
    let addMetadata = function() {
      $api('metadata/create', {
        metadataUid: record.metadataUid,
        id_user: props.demo.id_user,
        uid: props.demo.uid,
        type: record.metadata,
        value: record.value,
        content: record.data,
      }, $global).then((result) => {
        // Update value for relatedDocs
        let data = record.data;
        if (record.metadata === 'relatedDocs') {
          let relatedDocsUrl = record.content;
          data = `${relatedDocsUrl}${data}`;
        };

        // Add in `metadata` to determine if option should be disabled
        metadata.value.push({
          id: result.data.id,
          metadata: record.metadata,
          value: record.value,
          data,
        });

        // Update dataset
        zgRef.value.data = metadata.value;
      }).catch((error) => {
        $message({
          duration: 0,
          message: 'Could not update metadata because value is invalid',
          showClose: true,
          type: 'error',
        });
      });
    };

    // Create/fork demo if it has not been done
    let demoExists = props.demo.id_user || props.demo.uid;
    let query = $route.query;
    let demoToFork = Object.keys(query).includes('fork');

    if (!demoExists || demoToFork) {
      // Save demo not created or forked, then add metadata
      saveData(addMetadata);
    } else {
      // Add metadata
      addMetadata();
    }
  };

  function addTag(e) {
    let newTag = null;
    // Grab input of tag from select or input field
    if (adminTagAccess.value && typeof(e) === 'object') {
      if (e.target) newTag = e.target.value;
    } else {
      newTag = inputValue.value;
    }

    // Check if duplicate
    let duplicate = tags.value.filter((tag) => tag.name === newTag).length > 0;
    // Push tag if not duplicate
    if (newTag && !duplicate) {
      tags.value.push({
        id: null,
        name: newTag,
      });
    } else {
      $message({
        message: 'Tag already exists!',
        showClose: true,
        type: 'error',
      });
    }
    inputVisible.value = false;
    inputValue.value = '';
  };

  function copyLink(filename) {
    copyBuffer.value.value = imageLink(filename);
    copyBuffer.value.select();
    document.execCommand('copy');
    $message({
      message: 'Link copied to clipboard!',
      showClose: true,
      type: 'success',
    });
  };

  function copyShareLink(){
    copyBuffer.value.value = shareLink.value;
    copyBuffer.value.select();
    document.execCommand('copy');
    $message({
      message: 'Link copied to clipboard!',
      showClose: true,
      type: 'success',
    });
  };

  function currentTab(tab) {
    if (tab.props.label === 'Metadata') {
      // Initialize ZingGrid
      setupZingGrid();
    }
  };

  function deleteDemo() {
    $confirm('Are you sure you want to delete this demo?', 'Warning', {
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
      type: 'warning',
    })
    .then(() => {
      $api('demo/delete', {
        slug: props.demo.uid,
      }, $global)
      .then(() => {
        $message({
          message: 'Demo successfully deleted!',
          showClose: true,
          type: 'success',
        });
        if ($route.path !== '/demos') $router.push('/demos');
      });
    })
    .catch((error) => {
      $message({
        duraion: 0,
        message: 'Demo could not be deleted.',
        showClose: true,
        type: 'warning',
      })
    });
  };

  function deleteFile(filename) {
    $api('file/delete', {
      slug: filename,
    }, $global)
    .then(() => {
      $message({
        message: 'File deleted!',
        showClose: true,
        type: 'success',
      });
      getFiles();
    })
    .catch((error) => {
      $message({
        duration: 0,
        message: 'File could not be deleted!',
        showClose: true,
        type: 'error',
      });
      getFiles();
    });
  };

  function deleteMetadataRecord(data) {
    $api('metadata/delete', {
      id: data.id,
      id_user: props.demo.id_user,
      uid: props.demo.uid
    }, $global).then(() => {
      // Remove in `metadata` to determine if option is disabled
      let index = metadata.value.findIndex((meta) => meta.metadata === data.metadata && meta.value === data.value);
      metadata.value.splice(index, 1);

      // Determine if "zc-gallery" tag is to be removed
      let zcGalleryTag = tags.value.find(t => t.name === 'zc-gallery');
      let chartType = metadata.value.find(m => m.metadata === 'chartType');
      let vanityUrl = metadata.value.find(m => m.metadata === 'vanityUrl');
      if (zcGalleryTag && (!chartType || !vanityUrl)) {
        // Remove "zc-gallery" tag
        removeTag(zcGalleryTag.id, zcGalleryTag.name);

        // Send warning message about tag removal
        $message({                                                                  
          duration: 0,
          showClose: true,
          message: 'The "zc-gallery" tag is removed. To add again, make sure to have both "Chart Type" and "Vanity Url" metadatas set.',
          type: 'warning',
        });
      };
    });
  };

  function download() {
    window.location = `${window.location.origin}/demos/download/${props.demo.uid}`;
  };

  function fetchTags() {
    $api('tag/read', {
      slug: props.demo.uid,
    }, $global)
    .then((result) => {
      tags.value = result.data;
    })
    .catch((error) => {
      $message({
        duration: 0,
        message: 'Could not retrieve tags',
        showClose: true,
        type: 'error',
      });
    });
  };

  /**
   * @description Given the file name, returns the image link
   * @param {String} filename - file name of the image
   */
  function imageLink(filename) {
    let isLocalhost = window.location.hostname === 'localhost';
    let protocol = isLocalhost ? 'http' : 'https';
    let hostname = isLocalhost ? 'localhost:8090' : window.location.hostname;
    return `${protocol}://${hostname}/api/file/${props.demo.uid}/${encodeURIComponent(filename.split(props.demo.uid+'/')[1])}`;
  };

  /**
   * @description If given file is an image, set as background-image of div, else
   * returns false so that a default thumbnail for files is used. 
   * File is determined as an image by checking through a regex
   * @param {String} file - name of file (includes file extension to determine mime-type)
   */
  function fileThumbnailStyle(file) {
    // Check if image
    let isImage = file.match(/\.(jpeg|jpg|gif|png)$/) != null;
    // Set background image
    return isImage ? `background-image: url(${imageLink(file)});` : false;
  };

  /**
   * @description Get files associated to demo from Google Cloud Storage.
   * @param {Number} expected - Expected final files length. If files are not added, keep
   * fetching files until there.
   */
  function getFiles(expected) {
    if (props.demo.uid) {
      $api(`file/list`, {
        slug: props.demo.uid,
      }, $global)
      .then(response => {
        // Update file list for "Uploaded Files" section
        files.value = response.data.filter((filename) => !filename.includes('_preview'));
        // Check if uploaded images added to file list. Recall until files added
        let curLength = files.value.length
        if (expected && curLength < expected) {
          setTimeout(() => {
            getFiles(expected);
          }, 500);
        }
      })
      .catch(error => {
        // Fails usually because demo just created and files not yet created. Retry until created.
        setTimeout(() => {
          getFiles();
        }, 1000);
      });
    }
  };

  function handleClose() {
    emit('toggle-visibility');
  };

  /**
   * @description Opens up the Filestack client with specified options to handle file uploaders.
   */
  function openFilestack() {
    filestackClient.value.picker(filestackOptions.value).open();
  };

  /**
   * @description Before save, prepare data. Then emit event to parent
   * @param {Function} cb - callbacka after saving
   */
  function saveData(cb) {
    emit('save', null, null, null, null, cb);
  };

  function removeTag(id, name) {
    if(id === null) {
      // Just sort via filters and remove the pending tag to save, otherwise ajax to remove from server
      tags.value = tags.value.filter((tag) => tag.name !== name);
    } else {
      $api('tag/delete', {
        slug: id,
        data: {
          override: adminSaveOverride.value,
        }
      }, $global)
      .then((result) => {
        // Remove tag from UI
        tags.value = tags.value.filter((tag) => {
          if (tag.name !== name) return tag;
        });
      })
      .catch((error) => {
        $message({
          duration: 0,
          message: 'Could not delete tag',
          showClose: true,
          type: 'error',
        })
      });
    }
  };

  function showInput() {
    inputVisible.value = true;
    nextTick(_ => {
      if (saveTagInput.value) saveTagInput.value.$refs.input.focus();
      else document.querySelector('.el-select.input-new-tag input').focus();
    });
  };

  /**
   * @description Sets z-index of navbar and app content to 0 to prevent styles from
   * interrupting with Filestack's mask transition when opening Filestack client.
   * @param {Boolean} setup - Flag to determine to setup z-index to 0 or back to
   * original values
   */
  function setZIndex(setup) {
    // Save references
    if (!$content.value) $content.value = document.querySelector('.app-content');
    if (!$nav.value) $nav.value = document.querySelector('.nav');

    // Set z-index
    if (setup) {
      // Set z-index to 0
      if ($content.value) $content.value.style.zIndex = 0;
      if ($nav.value) $nav.value.style.zIndex = 1;
    } else {
      // Remove z-index
      if ($content.value) $content.value.style.removeProperty('z-index');
      if ($nav.value) $nav.value.style.removeProperty('z-index');
    }
  };

  function initZingGrid() {
    if (demoSettingsMetadata.value && !zgRef.value) {
      let columns = [{
        type: 'selector',
      }, {
        index: 'id',
        hidden: true,
        editor: false,
      }, {
        index: 'metadata',
      }, {
        index: 'value',
      }, {
        index: 'data',
      }, {
        type: 'remover',
      }];

      zgRef.value = new ZingGrid({
        caption: 'Metadata',
        compact: true,
        columns,
        data: JSON.stringify((metadata.value && metadata.value.length > 0) ? metadata.value : []),
        editor: 'modal',
        cellEditor: 'disabled',
        filter: true,
        layout: 'row',
        layoutControls: 'disabled',
        nodata: 'No Metadata',
        pager: true,
        pageSize: 10,
        search: true,
        sort: true,
      });

      // Event listners
      zgRef.value.addEventListener('data:record:delete', (e) => {
        deleteMetadataRecord(e.detail.ZGData.data);
      });
      zgRef.value.addEventListener('data:record:insert', (e) => {
        addMetadataRecord(e.detail.ZGData.data);
      });
      demoSettingsMetadata.value.appendChild(zgRef.value);
    } else if (zgRef.value) {
      zgRef.value.setData(metadata.value);
    }
  };

  function setupZingGrid() {
    if (demoSettingsMetadata.value) zgRef.value = demoSettingsMetadata.value.querySelector('zing-grid');  
    $api('metadata/read', {
      uid: props.demo.uid,
      format: 'grid'
    }, $global).then((result) => {
      metadata.value = result.data;
      initZingGrid();
    });
  };

  function showSettings(curSetting) {
    return props.settings.includes('all') || props.settings.includes(curSetting);
  };

  /**
   * @description Update autocomplete settings when user makes changes to settings
   * @param {Object} node - node whose state changed. Object contains properties 'check', 'id', and 'label' of node.
   * @param {Boolean} val - node current state
   * @param {Boolean} subtree - subtree
   */
  function updateAutoCompleteSettings(node, val, subtree) {
    if (node.id && autoComplete.value) {
      if (val) autoComplete.value.push(node.id);
      else autoComplete.value.splice(autoComplete.value.indexOf(node.id), 1);
      $api('user/update', {
        settings_autocomplete: autoComplete.value.toString(),
      }, $global)
      .then((response) => {
        $store.dispatch('user/refresh', $global);
      })
      .catch((result) => {
        $message({
          duration: 0,
          message: 'Unable to update autoComplete settings',
          showClose: true,
          type: 'error',
        });
      })
    }
  };

  /**
   * @description Update autoupdate settings when user makes changes to settings
   * @param {Boolean} val - new value of switch
   */
  function updateAutoUpdateSettings(val) {
    $api('user/update', {
      settings_autoupdate: val === true ? 'true' : 'false',
    }, $global)
    .then((response) => {
      $store.dispatch('user/refresh', $global);
    })
    .catch((result) => {
      $message({
        duration: 0,
        message: 'Unable to update autoupdate settings',
        showClose: true,
        type: 'error',
      });
    })
  };

  /**
   * @description Update docs tooltip settings when user makes changes to settings
   * @param {Boolean} val - new value of switch
   */
  function updateDocsToolipsSettings(val) {
    $api('user/update', {
      settings_docs: val === true ? 'true' : 'false',
    }, $global)
    .then((response) => {
      $store.dispatch('user/refresh', $global);
    })
    .catch((result) => {
      $message({
        duration: 0,
        message: 'Unable to update documentation hints settings',
        showClose: true,
        type: 'error',
      });
    })
  };
</script>

<style>
  .settings__dialog {
    width: 80% !important;
    max-width: 60rem;
  }
  .settings__dialog .el-dialog__body {
    padding: 30px 3rem 20px 30px;
  }

  [demo-settings="metadata__add"] {
    margin-top: 1rem;
  }

  .demo-control__item {
    width: fit-content;
  }
  .settings__dialog .demo-control__item {
    max-width: 400px;
  }
  
  .settings__dialog #pane-1 .el-input-icon {
    right: 0.6rem;
    top: 0.1rem;
  }

  .settings__dialog #pane-1 .el-input-group__append {
    padding: 0px 40px;
  }

  .settings__dialog #pane-2 .el-input-group__append {
    border: none;
    padding: 0 2rem;
  }

  .settings__dialog #pane-0 .input-new-tag .el-select__wrapper {
    height: 24px !important;
    font-size: var(--el-tag-font-size) !important;
  }

  .settings__dialog #pane-0 .el-tag,
  .settings__dialog #pane-0 .button-new-tag  {
    margin: 10px 10px 0 0;
  }

  .settings__dialog section header {
    display: flex;
    align-items: center;
  }

  .settings__dialog section header * + * {
    margin-left: 1rem;
  }

  .upload-dialog__file__thumbnail--image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 28px;
    margin-right: 0.5rem;
    width: 35px;
  }

  .upload-dialog__file__thumbnail--text {
    height: 28px;
    margin-right: 0.5rem;
    position: relative;
    right: 2px;
    width: 27px;
  }

  [demo-settings="metadata"] zing-grid {
    border: 0;
    margin-bottom: 1rem;
  }
  [demo-settings="metadata"] zing-grid zg-status {
    display: none;
  }
</style>