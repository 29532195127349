<template>
  <default-layout :editorTheme="editorTheme">
    <el-alert
      class="editor__alert"
      v-show="showNotice"
      title="[Warning: Not Forking]"
      type="warning"
      center
      effect="dark"
      show-icon>
      <p class="el-alert__description">Admin, you are editing demo directly because you are coming from the ZingChart/ZingGrid site.
        <a :href="demoUrl">Click here to fork demo.</a></p>
    </el-alert>

    <!-- EDITOR CONTROL BAR -->
    <header ref="$el" class="editor__controls">
      <section class="editor__controls--left" v-show="!isMobile">
        <el-input v-model="demoTitle" placeholder="Untitled Demo" size="small" maxlength="75"></el-input>
      </section>
      <!-- Run and settings -->
      <section class="editor__controls--right">
        <toggle-editorPane @update="updateControls">
        </toggle-editorPane>
        <!-- Editor Settings -->
        <editor-settings
          @update="updateEditorSettings"
          type="primary"
          size="small"
          :uid="uid">
        </editor-settings>
        <!-- Save Dropdown-->
        <template v-if="authenticated">
          <el-dropdown split-button type="primary" @click="saveDemo(null)" @command="selectSaveOption" size="small">
            <span ref="$saveIconWrapper" class="editor__save__icon"></span>&nbsp;<span class="action-text">SAVE</span>
            <template v-slot:dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="personal">Personal</el-dropdown-item>
                <el-dropdown-item v-if="adminSaveOverride" command="updateOwner">Update Owner</el-dropdown-item>
                <el-dropdown-item disabled divided>Save to Group:</el-dropdown-item>
                <el-dropdown-item v-for="group in myGroups" :key="group.id" :command="group.id">{{group.name}}</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </template>
        <template v-else>
          <lock @beforeLogin="saveLocally" size="small" type="primary"><font-awesome-icon :icon="['fas', 'save']" size="1x"/>&nbsp;<span class="action-text">Sign Up to Save</span></lock>
        </template>
        <!-- TOOLTIP -->
        <el-button-group>
          <el-tooltip class="item" effect="dark" content="cmd(ctrl) + p" placement="top-start" :open-delay="1000">
            <el-button v-if="!autoUpdate" @click="createPreview(null, null, true)" size="small">
              <font-awesome-icon :icon="['fas', 'play']" size="1x"/>&nbsp;<span class="action-text">RUN</span>
            </el-button>
          </el-tooltip>
            <el-tooltip v-if="authenticated" class="item" effect="dark" :content="forkMessage" :disabled="uid && !isForking && forkReady ? true : false" placement="top-start" :open-delay="1000">
              <el-button @click="forkToSave()" :class="uid && !isForking && forkReady ? '' : 'is-disabled'" size="small">
                <template v-if="!uid || isForking"><font-awesome-icon :icon="['fas', 'code-branch']" size="1x"/>&nbsp;<span class="action-text">FORK</span></template>
                <template v-else-if="!forkReady"><font-awesome-icon :icon="['fas', 'spinner']" spin size="1x"/>&nbsp;<span class="action-text">FORK</span></template>
                <template v-else><font-awesome-icon :icon="['fas', 'code-branch']" size="1x"/>&nbsp;<span class="action-text">FORK</span></template>
              </el-button>
            </el-tooltip>
          <el-button v-if="assets && assets.length !== 0" @click="openAssets" size="small">
            <font-awesome-icon :icon="['fas', 'file-code']" size="1x"/>&nbsp;<span class="action-text">ASSETS</span>
          </el-button>
          <el-button v-show="authenticated" @click="settingsVisible = true"  size="small">
            <font-awesome-icon :icon="['fas', 'cog']" size="1x"/>&nbsp;<span class="action-text">SETTINGS</span>
          </el-button>
        </el-button-group>
      </section>
    </header>


    <div class="flex content__wrap--sidebar">
      <!-- SIDEBAR -->
      <create-sidebar
        @resize="handleResize()"
        @show="toggleSidebar()"
        :uid="demo.uid"
        :show="showSidebar"
      ></create-sidebar>


      <!-- CONTENT -->
      <div :class="contentClass">
        <section ref="editor" class="editor">
          <section :editorLayout="editorLayout">
            <section class="editor__top">
              <section class="editor__code-container">
                <div :style="htmlStyle" ref="html" class="editor__code editor__code--html">
                  <header class="editor__code-header">
                    <div>HTML</div>
                    <div v-if="!isMobile" class="editor__maximize-icon" @click="maximizeWindow('html')"><font-awesome-icon :icon="['fas', 'window-maximize']"/></div>
                  </header>
                  <section ref="codeHTMLWrap" class="editor__codewrap">
                    <studio-editor
                      ref="codeHTML"
                      @update="updateCode"
                      :autocomplete="autoComplete.indexOf('html') !== -1"
                      :code="htmlCode"
                      :font-family="editorFontFamily"
                      :font-size="editorFontSize"
                      language="html"
                      :theme="editorTheme">
                    </studio-editor>
                  </section>
                </div>
                <div @mousedown="setActiveResizer('html', $event)" class="editor__resizer editor__resizer--code" v-show="showHTML && (showCSS || showJS)">
                  <font-awesome-icon :icon="['fas', 'ellipsis-v']" size="1x"/>
                  <font-awesome-icon :icon="['fas', 'ellipsis-h']" size="1x"/>
                </div>
                <div :style="cssStyle" ref="css" class="editor__code editor__code--css">
                  <header class="editor__code-header">
                    <div>CSS</div>
                    <div v-if="!isMobile" class="editor__maximize-icon" @click="maximizeWindow('css')"><font-awesome-icon :icon="['fas', 'window-maximize']"/></div>
                  </header>
                  <section ref="codeCSSWrap" class="editor__codewrap">
                    <studio-editor
                      ref="codeCSS"
                      @update="updateCode"
                      :autocomplete="autoComplete.indexOf('css') !== -1"
                      :code="cssCode"
                      :font-family="editorFontFamily"
                      :font-size="editorFontSize"
                      language="css"
                      :theme="editorTheme">
                    </studio-editor>
                  </section>
                </div>
                <div v-if="showCSS && showJS" @mousedown="setActiveResizer('css', $event)" class="editor__resizer editor__resizer--code">
                  <font-awesome-icon :icon="['fas', 'ellipsis-v']" size="1x"/>
                  <font-awesome-icon :icon="['fas', 'ellipsis-h']" size="1x"/>
                </div>
                <div :style="jsStyle" ref="js" class="editor__code editor__code--js">
                  <header class="editor__code-header">
                    <div>JS</div>
                    <div v-if="!isMobile" class="editor__maximize-icon" @click="maximizeWindow('js')"><font-awesome-icon :icon="['fas', 'window-maximize']"/></div>
                  </header>
                  <section ref="codeJSWrap" class="editor__codewrap">
                    <studio-editor
                      ref="codeJS"
                      @update="updateCode"
                      :autocomplete="autoComplete.indexOf('js') !== -1"
                      :code="jsCode"
                      :font-family="editorFontFamily"
                      :font-size="editorFontSize"
                      language="javascript"
                      :theme="editorTheme">
                    </studio-editor>
                  </section>
                </div>
              </section>
            </section>
            <section ref="$resizerPreview" @mousedown="setActiveResizer('preview', $event)" class="editor__resizer editor__resizer--preview">
              <font-awesome-icon :icon="['fas', 'ellipsis-h']" size="1x"/>
              <font-awesome-icon :icon="['fas', 'ellipsis-v']" size="1x"/>
            </section>
            <!-- Use a mask to track mouse events on the current window and not on the preview window-->
            <div class="iframe-mask" v-if="currentlyResizing"></div>
            <section ref="previewWrap" v-loading="previewLoading" class="editor__bottom">
              <div v-if="displayRunOnClick" @click="createPreview(null, null, true)" class="editor__bottom__runOnClick" :style="previewStyle">
                <div class="container">
                  <div class="clickToRun">
                    <font-awesome-icon :icon="['fas', 'play']" size="6x"/>
                    <p>Click to Run</p>
                  </div>
                </div>
              </div>
              <iframe v-else id="preview" ref="preview" class="preview" :style="previewStyle">
                Your browser does not support iframes.
              </iframe>
            </section>
          </section>
        </section>

        <!-- Settings -->
        <demo-settings
          @save="saveDemo"
          @toggle-visibility="settingsVisible = !settingsVisible;"
          @update-demo="updateDemoData"
          :demo="demo"
          :settings="['all']"
          :settingsVisible="settingsVisible"
        ></demo-settings>

        <!-- Create a Demo -->
        <el-dialog
          title="Create a Demo"
          v-model="createDemoVisible"
          class="template__dialog"
          >

          <section class="template__container">

            <el-tabs v-model="activeTemplateTab" @tab-click="handleClick">
              <el-tab-pane label="ZingChart Templates" name="zingchart">
                <section class="template__list">
                  <div class="template__item" v-for="(template, index) in zingchartTemplates" :key="index">
                    <p class="template__title">{{template.title}}</p>
                    <div class="template__image__wrapper">
                      <div 
                        class="template__image" 
                        :style="template.title != 'Blank Grid' ? getImageStyle(template.image) : null" 
                        v-html="template.title == 'Blank Grid' ? blankGrid : null">
                      </div>
                    </div>
                    <div class="template__description">{{template.description}}</div>
                    <el-button @click="setupTemplate(template)" class="template__button" size="small" type="primary">Create</el-button>
                  </div>
                </section>
              </el-tab-pane>
              <el-tab-pane label="ZingGrid Templates" name="zinggrid">
                <section class="template__list">
                  <div class="template__item" v-for="(template, index) in zinggridTemplates" :key="index">
                    <p class="template__title">{{template.title}}</p>
                    <div class="template__image__wrapper">
                      <div 
                        class="template__image" 
                        :style="template.title != 'Blank Grid' ? getImageStyle(template.image) : null" 
                        v-html="template.title == 'Blank Grid' ? blankGrid : null">
                      </div>
                    </div>
                    <div class="template__description">{{template.description}}</div>
                    <el-button @click="setupTemplate(template)" class="template__button" size="small" type="primary">Create</el-button>
                  </div>
                </section>
              </el-tab-pane>
              <el-tab-pane label="My Templates" name="myTemplates">
                <template v-if="myTemplates && myTemplates.length == 0">
                  <div style="margin: 1rem 2.3rem;">
                    <h3>You have not created any templates!</h3>
                    <p>You can convert a demo into a template in the demo's settings dialog.</p>
                  </div>
                </template>
                <template v-else>
                  <section class="template__list">
                    <div class="template__item" v-for="(template, index) in myTemplates" :key="index">
                      <p class="template__title">{{template.title}}</p>
                      <div class="template__image__wrapper">
                        <div class="template__image" :style="getImageStyle(template.image)"></div>
                      </div>
                      <div class="template__description">{{template.description}}</div>
                      <el-button @click="setupTemplate(template)" class="template__button" size="small" type="primary">Create</el-button>
                    </div>
                  </section>
                </template>
              </el-tab-pane>
            </el-tabs>

          </section>

        </el-dialog>

        <!-- UPDATE ASSETS -->
        <el-dialog
          dialog="assets"
          short
          title="Assets"
          v-model="assetsVisible">

          <el-form :model="assetsForm" label-width="120px">
            <!-- ZingGrid -->
            <el-form-item label="ZingGrid">
              <el-select v-model="assetsForm.zinggrid">
                <template v-for="asset in assets_zinggrid" :key="asset.name">
                  <el-option v-show="asset.public != 0 || (asset.public == 0 && adminAssetsAccess)" :label="asset.name" :value="asset.id"></el-option>
                </template>
                <el-option label="None" value="None"></el-option>
              </el-select>
              <svg-icon class="el-input-icon" icon="angle"></svg-icon>
            </el-form-item>

            <!-- ZingChart -->
            <el-form-item label="ZingChart">
              <el-select @change="clearZingChartAsset" v-model="assetsForm.zingchart">
                <template v-for="asset in assets_zingchart" :key="asset.name">
                  <el-option v-show="asset.public != 0 || (asset.public == 0 && adminAssetsAccess)" :label="asset.name" :value="asset.id"></el-option>
                </template>
                <el-option label="None" value="None"></el-option>
              </el-select>
              <svg-icon class="el-input-icon" icon="angle"></svg-icon>
            </el-form-item>

            <!-- ZingChart Version -->
            <el-form-item v-if="assetsForm.zingchart !== 'None' && assetsForm.zingchart !== '3'" label="Version">
              <el-select v-model="assetsForm.zingchartVersion">
                <template v-for="asset in assets_zingchart[assetsForm.zingchart].versions" :key="asset">
                  <el-option v-show="asset.public != 0 || (asset.public == 0 && adminAssetsAccess)" :label="asset" :value="asset"></el-option>
                </template>
              </el-select>
              <svg-icon class="el-input-icon" icon="angle"></svg-icon>
            </el-form-item>

            <!-- ZingChrt Modules -->
            <el-form-item v-if="assetsForm.zingchart !== 'None' && assetsForm.zingchart !== '3'" label="Modules">
              <el-select v-model="assetsForm.zingchartModule">
                <template v-for="asset in assets_zingchart[assetsForm.zingchart].modules[assetsForm.zingchartVersion]" :key="asset">
                  <el-option v-show="asset.public != 0 || (asset.public == 0 && adminAssetsAccess)" :label="asset" :value="asset"></el-option>
                </template>
                <el-option label="None" value="None"></el-option>
              </el-select>
              <svg-icon class="el-input-icon" icon="angle"></svg-icon>
            </el-form-item>

            <section class="settings__entry" style="display:flex; justify-content: flex-end; align-items: flex-end; flex:1;">
              <el-button @click="assetsVisible = false">Close</el-button>
              <el-button type="primary" @click="updateAsset">Update</el-button>
            </section>

          </el-form>
        </el-dialog>

        <!-- Signup to Access Premium Demo -->
        <el-dialog
          class="el-dialog__premium"
          short
          title="Signup to Access"
          v-model="premiumVisible">

          <p>This is a premium demo!</p> 
          <p>Login or Signup to gain access to this demo!</p>
          <p><lock @beforeLogin="saveLocally" type="primary"><font-awesome-icon :icon="['fas', 'save']" size="1x"/>&nbsp;<span class="action-text">Login / Signup</span></lock></p>
        </el-dialog>

        <!-- Prompts Demo Fork -->
        <el-dialog
          short
          forkDemo
          title="Fork Demo to Save."
          v-model="promptForkVisible">
          <p>This demo is not owned by you .</p>
          <p>Please fork demo to save your current changes.</p>
          <div class="dialog_container">
            <el-button @click="forkToSave($event)" type="primary">Fork Demo</el-button>
            <el-button v-show="adminSaveOverride" @click="saveDemo(null, null, true)" type="danger">Confirm Save</el-button>
          </div>
          <el-alert
            v-show="adminSaveOverride"
            type="warning"
            title="Note to admin!"
            :closable="false"
            show-icon>
            Saving demo does not update owner of demo.
          </el-alert>
        </el-dialog>

        <!-- Navigate without Saving Warning -->
        <my-dialog
          @close="saveWarningVisible=false"
          title="Unsaved Changes"
          type="danger"
          :visible="saveWarningVisible">
            <template v-slot:content>
              <div>
                <p>You will lose all unsaved progress if you leave this page.<br><b>Are you sure you want to proceed?</b></p>
              </div>
            </template>
            <template v-slot:options>
              <div>
                <el-button @click="leavePage" plain type="danger">Leave Page</el-button>
                <el-button @click="saveWarningVisible = false" type="primary">Stay on Page</el-button>
              </div>
            </template>
          </my-dialog>

        <!-- Update Owner of Demo -->
        <el-dialog
          short
          title="Update Owner of Demo"
          v-model="updateOwnerVisible">
          <el-form label-width="120px">
            <el-form-item label="Select User">
              <el-select
                v-model="newOwnerId"
                filterable
                placeholder="Enter user's email">
                <el-option
                  v-for="item in userOptions"
                  :key="item.id"
                  :label="userLabel(item)"
                  :value="item.id">
                </el-option>
              </el-select>
              <section style="display:flex; justify-content: flex-end; margin-top: 1.5rem; align-items: flex-end; flex:1;">
                <el-button @click="updateOwnerVisible = false">Close</el-button>
                <el-button @click="updateDemoOwner" type="primary" :disabled="!newOwnerId">Update</el-button>
              </section>
            </el-form-item>
          </el-form>
        </el-dialog>

      </div>
    </div>
  </default-layout>
</template>

<script setup>
  import { computed, getCurrentInstance, nextTick, onBeforeMount, onMounted, onBeforeUnmount, onUnmounted, ref, watch } from 'vue';
  import { computedAsync } from '@vueuse/core';
  import { onBeforeRouteLeave, onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router';
  import { useStore } from 'vuex';
  import axios from 'axios';
  import CreateSidebar from './components/CreateSidebar.vue';
  import DefaultLayout from '../layouts/Default.vue';
  import defaultTemplate from './default.js';
  import DemoSettings from './DemoSettings.vue';
  import EditorSettings from '../../components/EditorSettings.vue';
  import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
  import Lock from "../../components/Lock.vue";
  import MyDialog from '../../components/MyDialog.vue';
  import routes from '../../routes/main.js';
  import StudioEditor from '../../components/StudioEditor.vue';
  import SvgIcon from '../../components/SvgIcon.vue';
  import ToggleEditorPane from '../../components/ToggleEditorPane.vue';
  import editorPaneComposable from './../../mixins/editorPanes.js';
  import groupsComposable from '../../mixins/groups.js';
  import permissionsComposable from '../../mixins/permissions.js';

  const instance = getCurrentInstance();
  const $global = instance.appContext.config.globalProperties;
  const $api = $global.$api;
  const $message = $global.$message;
  const $route = useRoute();
  const $router = useRouter();
  const $store = useStore();
  const $el = ref(null);
  const $saveIconWrapper = ref(null);
  const $resizerPreview = ref(null);
  const activeTemplateTab = ref(($route.query && $route.query.type && ($route.query.type.toLowerCase() === 'zingchart' || $route.query.type.toLowerCase() === 'zinggrid')) ? $route.query.type.toLowerCase() : $store.state.user['referrer'].toLowerCase());
  const assetsForm = ref({
    zinggrid: 'None',
    zingchart: 'None',
    zingchartVersion: 'Latest',
    zingchartModule: 'None',
  });
  const assets = ref([]);
  const assets_zinggrid = ref({});
  const assets_zingchart = ref({});
  const blankGrid = ref('<svg style="margin:auto" width="161" height="52" fill="none" xmlns="http://www.w3.org/2000/svg"><path stroke="#fff" d="M.5.5h160v51H.5z"/><path d="M1 1h159v10H1V1z" fill="#fff"/><path stroke="#fff" d="M0 41.5h161M0 31.5h161M0 20.5h161M0 10.5h161M81.5 0v51"/></svg>');
  const currentlySaving = ref(false); // disable save button for couple seconds after save
  const saveButtonTimeout = ref(null); // timeout for voiding rage clicking save button
  const isForking = ref(('fork' in $route.query));
  const forkReady = ref(!('fork' in $route.query));
  const isTemplate = ref(false);
  const editorSetting = ref('bottom');
  const editorFontFamily = ref('monospace');
  const editorFontSize = ref('13px');
  const editorLayout = ref('row-bottom');
  const editorTheme = ref('default');
  const firstPreviewRun = ref(false);
  const previewLoading = ref(false);
  const zingchartTemplates = ref([]);
  const zinggridTemplates = ref([{
    created: '2018-07-26T23:41:55.000Z',
    css: 'body{background:#e6e6e6;}',
    description: 'Simple ZingGrid component example',
    html: ``,
    id_user: 'auth0|5b3ab422fbdcb762d016f44a',
    image: 'RJ68SIF1/_preview_1532623417768.png',
    is_template: 1,
    js: '',
    last_updated: '2018-07-26T23:43:37.000Z',
    public: 1,
    tags: [],
    title: 'Simple Grid',
    uid: 'RJ68SIF1', 
    desktop_height: 1000,
    desktop_grid: 'RJ68SIF1/_preview_1532623417768.txt',
    mobile_image: 'RJ68SIF1/_preview_1532623417768.png',
    mobile_height: 1200,
    mobile_grid:  'RJ68SIF1/_preview_1532623417768.txt',
  }]);
  const myTemplates = ref([]);
  const createDemoVisible = ref(false);
  const premiumVisible = ref(false);
  const demoTemplate = ref(null);
  const demoTitle = ref(null);
  const demoType = ref(null);
  const demoDescription = ref(null);
  const demoOwner = ref(null);
  const demoMetadata = ref(null);
  const newOwnerId = ref(null);
  const userOptions = ref([]);
  const files = ref([]);
  const createPath = ref('/demos/create/');

  // DEMO TYPE REGEX
  const zcScriptRegex = ref(/script\s+src.*(cdn\.zingchart\.com|\/api\/asset\/zingdata\/\d\.js).*\/script/i);
  const zgScriptRegex = ref(/script\s+src.*cdn\.zinggrid\.com.*\/script/i);

  // EDITOR SETTINGS
  const autoUpdate = ref($store.state.user['settings_autoupdate'] && $store.state.user['settings_autoupdate'] === 'true' ? true : false);
  const autoComplete = ref($store.state.user['settings_autocomplete'] ? $store.state.user['settings_autocomplete'].split(',') : []);
  const docsTooltip = ref($store.state.user['settings_docs'] && $store.state.user['settings_docs'] === 'true' ? true : false);

  // TAGS
  const tags = ref([]);

  // UI Structures
  const htmlCode = ref(false);
  const cssCode = ref(false);
  const jsCode = ref(false);
  const preview = ref(null);
  const promptForkVisible = ref(false);
  const uploadDialogVisible = ref(false);
  const shareDialogVisible = ref(false);
  const isPublic = ref(false);
  const templateListBottom = ref(null);
  const addScrollEvent = ref(false);
  const showSidebar = ref('hidden');
  const showNotice = ref(false);

  // DB/USER/AUTH
  const uploadHeaders = ref({
    Authorization: "Bearer " + $store.state.auth.idToken
  });
  const existing = ref(false);

  // COMPILE
  const dirty = ref(false);
  const updateThreshold = ref(1000);
  const iframeTarget = ref('about:blank');
  const uid = ref(null);
  const groupId = ref(null);
  const lastModified = ref(new Date().getTime());
  const cache = ref(null);
  const cacheUid = ref(null);

  // DIALOGS
  const assetsVisible = ref(false);
  const forkVisible = ref(false);
  const saveWarningVisible = ref(false);
  const saveWarningNext = ref(null);
  const tagToAdd = ref('');
  const selectedTemplate = ref(null);
  const prevSelectedDefaultTemplate = ref(null);
  const prevSelectedMyTemplate = ref(null);
  const settingsVisible = ref(false);
  const updateOwnerVisible = ref(false);

  // Premium
  const isPremium = ref(false); // TODO used for testing premium demos
  const premiumText = ref('Signup to access!');
  const originalHtml = ref(null);
  const originalCss = ref(null);
  const originalJs = ref(null);

  // Run on click
  const runOnClick = ref(false);
  const runOnClickQuery = ref($route.query.runonclick == 1);
  const hideRunOnClick = ref(false);

  const adminAssetsAccess = computedAsync(
    async () => {
      return await checkPermission('admin_assets_access', null, null, $store);
    }, null
  );
  const adminMetadataUpdate = computedAsync(
    async () => {
      return await checkPermission('admin_metadata_update', null, null, $store);
    }, null
  );
  const adminSaveOverride = computedAsync(
    async () => {
      return await checkPermission('admin_save_override', null, null, $store);
    }, null
  );
  const adminTagAccess = computedAsync(
    async () => {
      return await checkPermission('admin_tag_access', null, null, $store);
    }, null
  );
  const adminTemplateCreate = computedAsync(
    async () => {
      return await checkPermission('admin_template_create', null, null, $store);
    }, null
  );
  const premiumContentSet = computedAsync(
    async () => {
      return await checkPermission('premium_content_set', null, null, $store);
    }, null
  );
  const premiumContentView = computedAsync(
    async () => {
      return await checkPermission('premium_content_view', null, null, $store);
    }, null
  );

  const cachedDemo = computed(() => {
    return $store.state.demo.data;
  });
  const demo = computed(() => {
    return {
      autoComplete: autoComplete.value,
      autoUpdate: autoUpdate.value,
      demoTemplate: demoTemplate.value,
      description: demoDescription.value,
      docsTooltip: docsTooltip.value,
      files: files.value,
      isPremium: isPremium.value,
      isPublic: isPublic.value,
      isTemplate: isTemplate.value,
      runOnClick: runOnClick.value,
      tags: tags.value,
      title: demoTitle.value,
      uid: uid.value,
      id_user: demoOwner.value,
      type: demoType.value,
      metadata: demoMetadata.value,
    }
  });
  const demoUrl = computed(() => {
    return `${createPath.value}${uid.value}?fork`;
  });
  const contentClass = computed(() => {
    return `content${showSidebar.value === 'show' ? '--hasSidebar--wide' : ''} editor`;
  });
  const isMobile = computed(() => {
    return $store.getters['ui/isMobile'];
  });
  const formattedTags = computed(() => {
    return tags.value.map((tag) => tag.name);
  });
  // AUTH/USER
  const authenticated = computed(() => {
    return !!$store.state.auth.idToken;
  });
  const userID = computed(() => {
    // After getting user id, check if demo should be forked or edited
    return $store.state.user.user_id;
  });
  const displayRunOnClick = computed(() => {
    return !hideRunOnClick.value && (runOnClick.value || runOnClickQuery.value);
  });
  const forkMessage = computed(() => {
    return !uid.value || isForking.value ? 'Please save this demo before forking it' : 'Please wait until demo ready to be forked';
  });

  const { myGroups, fetchGroups } = groupsComposable({ $global, $store });
  const { currentlyResizing, previewSize, cssStyle, htmlStyle, jsStyle,
    previewStyle, showHTML, showCSS, showJS, setupEditorPanes,
    cleanupEditorPanes, handleResize, maximizeWindow, resizePreview, setActiveResizer, updateControls }
    = editorPaneComposable({$store, instance, $resizerPreview, editorLayout, isMobile});
  const { checkPermission } = permissionsComposable();

  watch(jsCode, (newVal, oldVal) => {
    if (oldVal) {
      dirty.value = true;
      lastModified.value = new Date().getTime();
    };
  });
  watch(htmlCode, (newVal, oldVal) => {
    if (oldVal) {
      dirty.value = true;
      lastModified.value = new Date().getTime();
    };
  });
  watch(cssCode, (newVal, oldVal) => {
    if (oldVal) {
      dirty.value = true;
      lastModified.value = new Date().getTime();
    };
  });

  // ROUTES
  onBeforeRouteLeave((to, from, next) => {
    // Add attribute on enter
    document.body.removeAttribute('hide-footer');
    // Display save warning prompt before route change
    if (!to.path.includes('/404')) {
      if (from.path.includes('/demos/create') && (dirty.value || (dirty.value && window.popStateDetected))) {
        saveWarningVisible.value = true;
        saveWarningNext.value = next;
      } else {
        window.popStateDetected = false;
        next();
      }
    } else {
      next(false);
    };
  });

  onBeforeRouteUpdate((to, from, next) => {
    // Remove attribute on leave
    document.body.removeAttribute('hide-footer');
    // Display save warning prompt when params change
    if (to.path.includes(createPath.value) && from.path.includes(createPath.value) && dirty.value && !isForking.value) {
      saveWarningVisible.value = true;
      saveWarningNext.value = next;
      next(false);
    } else {
      next();
    }
  });
  
  // NOTE: beforeMount and mounted runs twice on page load!!! 
  //       In AppShell.vue, this component loads first in <demos-create-view> then again in <app>.
  //       When page loads, the user is not authenticated yet, so <demo-create-view> loads. After the user
  //       authenticates, the <app> loads with Create.vue. Therefore anything in beforeMount and mount may load
  //       twice, so use some flag to 
  onBeforeMount(() => {
    // Loads locally saved demo if applicable
    let forkID = $route.query.fork ? `-${$route.query.fork}` : '';
    cache.value = localStorage.getItem(`demo${forkID}`);
    // TODO: localStorage is async? find a way around this...
    let auth = authenticated.value;
    setTimeout(() => {
      if (cache.value) {
        const { html, js, css, metadata, uid } = JSON.parse(cache.value);
        htmlCode.value = html ? html : '';
        cssCode.value = css ? css : '';
        jsCode.value = js ? js : '';
        cacheUid.value = uid;
        demoMetadata.value = JSON.parse(metadata);
        createPreview();
        if (auth) {
          saveDemo();
          localStorage.removeItem(`demo${forkID}`);
        };
      }
    }, 1500);

    setupEditorPanes();
  });

  onMounted(() => {
    window.addEventListener('beforeunload', beforeUnload);
    window.addEventListener('keydown', hotKeys);

    if ($store.state.auth.idToken) setupAssets();

    // Hide footer
    document.body.setAttribute('hide-footer', '');

    // Trigger preview
    if ($route.params && $route.params.uid) {
      // Existing demo
      uid.value = $route.params.uid;
      loadDemo();
    } else if(cache.value) {
      // Demo from signup flow
      uid.value = null;
      existing.value = false;
      dirty.value = false;
    } else {
      // Brand new demos
      uid.value = null;
      existing.value = false;
      createDemoVisible.value = true;
      getTemplates('zingchart');
      getTemplates('zinggrid');
    }
    // Fetch files if authed
    if ($store.state.auth.idToken) {
      getFiles();
    }
    // Kick off auto-render fn
    trackChanges();

    // Adjust the preview size on first loadDemo
    previewSize.value.height = instance.refs && instance.refs.editor ? instance.refs.editor.getBoundingClientRect().height / 2 : 400;
    previewSize.value.width = instance.refs && instance.refs.editor ? instance.refs.editor.getBoundingClientRect().width * 0.6 : 0;

    cleanupEditorPanes();
    fetchGroups();
  });

  onBeforeUnmount(() => {
    window.clearTimeout(saveButtonTimeout.value);
  });

  onUnmounted(() => {
    window.removeEventListener('beforeunload', beforeUnload);
    window.removeEventListener('keydown', hotKeys);

    $store.dispatch('demo/consumeDemo');
  });

  function getCustomThumbnail() {
    if (files.value) {
      for (let file in files.value) {
        if (file) {
          // Remove extension (if filename contains '.', get end half only)
          let filename = files.value[file].split('.').slice(0, -1).pop();
          if (filename && /demo(_|-)thumbnail$/.test(filename.toLowerCase())) {
            return files.value[file];
          }
        };
      }
    }
    return null;
  };

  function updateCode(type, code) {
    // Map lanaguage to variable holding code
    if (type === 'html') htmlCode.value = code;
    else if (type === 'css') cssCode.value = code;
    else jsCode.value = code;

    // Load first preview after editor compiles code
    if (!firstPreviewRun.value && type === 'html' && jsCode.value === '') {
      firstPreviewRun.value = true;
      createPreview();
    } else if (!firstPreviewRun.value && type === 'javascript') {
      firstPreviewRun.value = true;
      createPreview();
    };
  };

  function hotKeys(e) {
    if((navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey)) {
      switch(e.keyCode){
        case 80 : //P
          e.preventDefault();
          createPreview(null, null, true);
          break;
        case 83 : //S
          e.preventDefault();
          saveDemo();
          break;
      }
    }

    // Create Demo
    if (e.keyCode === 13 && createDemoVisible.value) setDemo();
  };

  function beforeUnload(e) {
    // If we haven't been passed the event get the window.event
    if(dirty.value){
      e.preventDefault();
        e = e || window.event;
        var message = 'You didn\'t save to the demo!';
        // For IE6-8 and Firefox prior to version 4
        if (e){e.returnValue = message;}
        // For Chrome, Safari, IE8+ and Opera 12+
        return message;
    }
  };

  function handleClick(tab) {
    $store.state.user['referrer'] = tab.paneName;
  };

  function removeTag(id, name, override) {
    if(id === null) {
      // Just sort via filters and remove the pending tag to save, otherwise ajax to remove from server
      tags.value = tags.value.filter((tag) => tag.name !== name);
    } else {
      $api('tag/delete', {
        slug: id,
        override
      }, $global)
      .then((result) => {
        fetchTags();
      })
      .catch((error) => {
        $message({
          duration: 0,
          message: 'Could not delete tag',
          showClose: true,
          type: 'error',
        })
      });
    }
  };

  function fetchTags() {
    $api('tag/read', {
      slug: uid.value,
    }, $global)
    .then((result) => {
      tags.value = result.data;
    })
    .catch((error) => {
      $message({
        duration: 0,
        message: 'Could not retrieve tags',
        showClose: true,
        type: 'error',
      });
    });
  };

  function download() {
    window.location = `${window.location.origin}/demos/download/${uid.value}`;
  };

  function getImageStyle(url) {
    return {
      'background-image': `url("https://storage.googleapis.com/${VUE_APP_CLOUD_ASSETS_BUCKET}/${url}")`,
    }
  };

  function setDemo() {
    $api('demo/retrieve', {
      slug: selectedTemplate.value.uid
    }, $global)
    .then((result) => {
      const {html, css, js} = selectedTemplate.value;
      jsCode.value = js ? js : '';
      htmlCode.value = html ? html : '';
      cssCode.value = css ? css : '';
      createDemoVisible.value = false;
      existing.value = false;
      nextTick(() => {
        dirty.value = false;
      });
    })
    .catch((error) => {
      $message({
        message: 'Could not retrieve assets',
        type: 'error',
      });
    });
  };

  /**
   * @description Places template as first in list
   * @param { String } title - title of template to place first in list
   * @param { Array } list - array of templates
   */
  function placeTemplateFirst(title, list) {
    if (list && list.length > 0) {
      // TODO: Setup database to support priority
      const index = list.findIndex(template => template.title === title);
      return ([list.splice(index, 1)[0], ...list]);
    }
  };

  /**
   * @description Get list of templates based on type specified
   * @param {String} type - type of template to grab
   */
  function getTemplates(type) {
    const url = `/api/demo?start=0&limit=100&sort_by=title&sort_direction=ASC&filter=[{"by":"is_template","value":"1","type":"demo"}, {"by":"type","value":"${type}","type":"demo"}]`;
    axios({
      url: url + '&template_default',
      method: 'get',
      headers: { 'Authorization': `Bearer ${$store.state.auth.idToken}` },
      json: true,
    })
    .then((result) => {
      const temp = result.data.results;
      if (type === 'zinggrid') zinggridTemplates.value = placeTemplateFirst('A Simple Grid', temp);
      else zingchartTemplates.value = temp;
      selectedTemplate.value = temp.length > 0 ? temp[0] : null;
      setTimeout(() => { updateMoreTemplates() }, 0);
    })
    .catch((error) => {
      $message({
        duration: 0,
        message: 'Could not load default templates',
        showClose: true,
        type: 'error',
      })
    });

    axios({
      url,
      method: 'get',
      headers: {
        'Authorization': `Bearer ${$store.state.auth.idToken}`,
      },
      json: true,
    })
    .then((result) => {
      myTemplates.value = result.data.results;
      myTemplates.value = placeTemplateFirst('A Simple Grid', myTemplates.value);
    })
    .catch((error) =>{
      $message({
        duration: 0,
        message: 'Could not load your templates',
        showClose: true,
        type: 'error',
      })
    });
  };

  function updateMoreTemplates() {
    setTimeout(() => {
      const templateItems = document.querySelectorAll(`#pane-${activeTemplateTab.value} .template__list .template__item`);
      const lastTemplateItem = templateItems[templateItems.length-1];
      const itemPos = lastTemplateItem ? lastTemplateItem.getBoundingClientRect() : null;
      const more = document.querySelector(`#pane-${activeTemplateTab.value} .template__item--more`);
      if (more) {
        if (itemPos && itemPos.bottom > templateListBottom.value) more.style.opacity = '1';
        else more.style.opacity = '0';
      }
    }, 0);
  };

  function handleOptions(command) {
    switch(command) {
      case 'download':
        download();
      break;
      case 'save':
        saveDemo();
      break;
      case 'upload':
        if(uid.value) {
          uploadDialogVisible.value = true;
        } else {
          $message({
            duration: 0,
            message: 'Cannot upload files without saving first',
            showClose: true,
            type: 'warning',
          })
        }
      break;
      case 'settings':
        settingsVisible.value = true;
      break;
      case 'share':
        shareDialogVisible.value = true;
      break;
    }
  };

  /**
   * @description Saves demo in localstorage to later load into studio 
   * @param {String} forkID - unique fork id associated to `demo-ID` in localstorage
   */
  function saveLocally(forkID) {
    let path = $route.path.replace($route.params.uid, '');
    if (forkID) path = `${path}?fork=${forkID}`;

    // Get metadata and only copy some
    let metadata = typeof demoMetadata.value === 'string' && demoMetadata.value.length > 0 ? JSON.parse(demoMetadata.value) : demoMetadata.value;
    metadata = metadataToCopy(metadata);

    localStorage.setItem('redirectPath', path);
    localStorage.setItem(
      forkID ? `demo-${forkID}` : 'demo',
      JSON.stringify({
        html: htmlCode.value,
        js: jsCode.value,
        css: cssCode.value,
        uid: uid.value,
        metadata: JSON.stringify(metadata || []),
      })
    );
  };

  function getFiles() {
    if(uid.value) {
      $api(`file/list`, {
        slug: uid.value,
      }, $global)
      .then(response => {
        if (typeof response.data === 'object') {
          files.value = response.data.filter((filename) => !filename.includes('_preview'));
        }
      });
    }
  };

  function setDemoData(data) {
    htmlCode.value = data.html || '';
    cssCode.value = data.css || '';
    jsCode.value = data.js || '';
    demoTemplate.value = data.template_type || '';
    demoTitle.value = data.title;
    demoDescription.value = data.description;
    demoOwner.value = data.id_user;
    tags.value = data.tags;
    groupId.value = data.id_grouping;
    isPremium.value = data.premium_template === 'true' ? true : false;
    labelDemo(groupId.value ? 'group' : 'personal');
    demoMetadata.value = data.metadata;
    runOnClick.value = Boolean(data.run_on_click);
    
    // Set demo data when not forking data
    let forkQuery = Object.keys($route.query).includes('fork');
    if (!forkQuery) {
      isTemplate.value = (data.is_template === 1);
      isPublic.value = (data.public === 1);
      uid.value = data.uid;
      existing.value = true;
    } 
    nextTick(() => {
      dirty.value = false;

      adminEditSetup();
    });
  };

  /**
   * @description If referrer if from zingchart or zinggrid site and user is admin,
   * allow admin to directly edit demo instead of forking.
   */
  function adminEditSetup() {
    let referrer = document.referrer.toLowerCase();
    let siteEdit = (referrer.includes('zingchart.com') || referrer.includes('zinggrid.com')) && userID.value === 2;
    let forkDemo = !existing.value;
    if (siteEdit && forkDemo) {
      // Disable forking
      existing.value = true;
      // Show notice to let admin know demo is not forking
      showNotice.value = true;
    }
  };

  function loadDemo() {
    if (uid.value && (uid.value !== cacheUid.value)) {
      if (cachedDemo.value && uid.value === cachedDemo.value.uid) {
        setDemoData(cachedDemo.value);
        $store.dispatch('demo/consumeDemo');
      } else {
        $api(`demo/retrieve`, {
          slug: uid.value
        }, $global)
        .then(response => {
          setDemoData(response.data);
          $store.dispatch('demo/cacheDemo', response.data);
        })
        .catch(error => {
          switch(error && error.response && error.response.status) {
            case 401:
              if (localStorage.getItem('startup_status')) location.reload();
              else if ($route.path !== '/401') $router.push('/401');
              break;
            default:
              $router.replace('/404');
              break;
          }
        });
      }
    }
  };

  function metadataToCopy(metadata) {
    let toCopy = ['chartType', 'relatedDocs'];
    return metadata.filter((m) => {
      if (toCopy.indexOf(m.metadata) > -1) return m;
    });
  };

  function trackChanges() {
    setInterval(() => {
      if (dirty.value && autoUpdate.value) {
        const now = new Date().getTime();
        // Check to see if enough time has passed to update since the last modification
        // if (now - updateThreshold.value > lastModified.value && instance.refs.preview) {
        if (now - updateThreshold.value > lastModified.value) {
          dirty.value = false;
          createPreview();
        }
      }
    }, 60);
  };

  /**
   * @description Updates preview of the code.
   * On first time load, an iframe is created and the src is set to a different domain
   * to prevent localstorage access.
   * After, if `destroy` is false, the contents of the iframe is cleared for writing
   * the updated demo.
   * Else is `destroy` is true, the iframe is removed and a new one is created because
   * unable to remove zingchart script when library assets updated.
   * @param {Event} e - native click event
   * @param {Boolean} destoy - to destroy and create new iframe instead of just
   * overwriting the contents
   * @param {Boolean} runOnClick - set true if preview is manually executed by user instead
   * of automatically from load or asset changes
   */
  function createPreview(e, destroy, runOnClick) {
    let manuallyRunPreview = displayRunOnClick.value && runOnClick;
    // Determine to display "Run on Click" or demo preview
    if (!displayRunOnClick.value || manuallyRunPreview) {
      // Display demo preview 
      let html = htmlCode.value ? htmlCode.value : '';
      let parts;
      // Upate demo type
      updateDemoType();
      // For premium demos, check if user has access
      if (isPremium.value === 'true' || isPremium.value === true) setupPremiumPreview();

      // Inject CSS
      parts = html.split("</head>");
      // TODO: duplicate injection...?
      html = `${parts[0]}<style>${cssCode.value ? cssCode.value : ''}</style>`  // Add demo css
        + `</head>${parts[1]}`;

      // Inject JS (and ZC license)
      let jsCodeTemp = `${jsCode.value}\n
        ${demoType.value.includes('zingchart') ? `zingchart.MODULESDIR = "https://cdn.zingchart.com/modules/";ZC.LICENSE = ['569d52cefae586f634c54f86dc99e6a9', 'b55b025e438fa8a98e32482b5f768ff5'];` : ''}
        ${demoType.value.includes('zinggrid') ? 
          `ZingGrid.setLicense(['${VUE_APP_ZG_LICENSE}']);
          window.addEventListener('load', () => {
            window.addEventListener('message', () => {
              (document.querySelectorAll('zing-grid')).forEach((grid) => {
                if (typeof grid.updateSize === 'function') grid.updateSize();
              });
            });
          });` 
          : ''}`;
      parts = html.split('</body>');
      html = `${parts[0]}<scr` + `ipt>${jsCodeTemp}</sc` + `ript>${parts[1]}`; // Add rest of demo (html/js)

      // Use existing iframe or create new if does not exist
      if (preview.value && !destroy) {
        // Refresh iframe
        preview.value.src = 'about:blank';
        preview.value.src = `${VUE_APP_PREVIEW_URL}/demos/preview`;
        previewLoading.value = true;

        // Send preview if ready
        setTimeout(() => {
          if (preview.value && preview.value.contentWindow) {
            preview.value.contentWindow.postMessage({
              type: 'preview',
              preview: html,
            }, preview.value.src);
            previewLoading.value = false;
          } else {
            setTimeout(() => {
              previewLoading.value = false;
            }, 500);
          };
        }, 500);
      } else {
        // Remove iframe is exists
        if (preview.value) {
          preview.value.remove();
          preview.value = null;
        };
        // Clear placeholder preivew
        instance.refs.previewWrap.innerHTML = '';     

        // Create iframe
        preview.value = document.createElement('iframe');
        preview.value.id = 'preview';
        preview.value.src = `${VUE_APP_PREVIEW_URL}/demos/view/${uid.value || (selectedTemplate.value ? selectedTemplate.value.uid : null)}`;
        preview.value.setAttribute('class', 'preview');
        preview.value.frameBorder = '0';
        preview.value.allow = 'midi; clipboard-read; clipboard-write';
        preview.value.sandbox = 'allow-same-origin allow-downloads allow-forms allow-modals allow-pointer-lock allow-popups allow-presentation allow-scripts allow-top-navigation-by-user-activation';
        instance.refs.previewWrap.appendChild(preview.value);
        previewLoading.value = false;
      };
    };
  };

  function setupPremiumPreview() {
    if (!premiumContentView.value) {
      originalHtml.value = htmlCode.value;
      originalCss.value = cssCode.value;
      originalJs.value = jsCode.value;
      htmlCode.value = `<!-- ${premiumText.value} -->`;
      cssCode.value = `/* ${premiumText.value} */`;
      jsCode.value = `// ${premiumText.value} `;
      premiumVisible.value = true;
    }
  };

  /**
   * @description Fork demo by opening "fork" url (?fork=forkID)
   * @param {Event} e - native click event
   * @param {String} [forkID] - unique fork id associated to `demo-ID` in localstorage
   */
  function forkDemo(e, forkID) {
    // Checks if button is disabled (cannot user Element's 'disabled' attribute because cannot display tooltip properly)
    let target = null;
    let _forkID = forkID ? `=${forkID}` : '';
    if (e) {
      if (e.srcElement.tagName === 'BUTTON') target = e.srcElement;
      else target = e.srcElement.closest('.el-button'); 
      if (!target.classList.contains('is-disabled')) {
        window.open(`${createPath.value}${uid.value}?fork${_forkID}`, '_blank');
      }
      promptForkVisible.value = false;
      forkVisible.value = false;
    } else {
      // Fork demo programatically (does not have native event object)
      window.open(`${createPath.value}${uid.value}?fork${_forkID}`, '_blank')
    }
    // Remove pending on fork button
    forkReady.value = true;
    // Set flags to not fork when attempting to save after successfully forking
    existing.value = true;
    dirty.value = true;
  };

  function forkId() {
    return crypto.randomUUID().replace(/\-/g, '').slice(0,8).toString('hex');
  };

  function forkToSave(e) {
    if (forkReady.value) {
      // Set flags to fork only when not attempting to override save
      existing.value = false;
      dirty.value = false;
      // Create id for fork
      let forkID = forkId();
      // Save locally to fork
      saveLocally(forkID);
      forkDemo(e, forkID);
    };
  };

  /**
   * @description Saves the demo
   * @param {String} type - determines if demo is saved as personal or specified group. If none provided, saved as last specified
   * @param {Boolean} displayMessage - determines which success message to display on save
   * @param {Boolean} override - admin-only; override save limitation by allowing user to save demo not longing to the user
   * @param {String} newOwner - admin-only; override save limitation by allowing user to save demo to another user by providing user's id
   * @param {Function} cb - callback after demo saved
   */
  function saveDemo(type, displayMessage = true, override, newOwner, cb) {
    // When saving a group demo as personal when not the demo owner, prompt user to fork demo instead
    if (groupId.value && type === 'personal' && demoOwner.value !== userID.value) {
      forkVisible.value = true;
      return;
    }
    // Sets label next to save button
    if (type) labelDemo(type);
    currentlySaving.value = true; // disable action
    isForking.value = false;
    forkReady.value = false;
    // Get demo type
    updateDemoType();
    // Determine type of grid
    const data = {
      html: originalHtml.value ? originalHtml.value : htmlCode.value ? htmlCode.value : '',
      js: originalJs.value ? originalJs.value : jsCode.value ? jsCode.value : '',
      css: originalCss.value ? originalCss.value : cssCode.value ? cssCode.value : '',
      id_user: userID.value,
      title: demoTitle.value,
      description: demoDescription.value,
      metadata: typeof demoMetadata.value === 'Object' ? JSON.stringify(demoMetadata.value) : demoMetadata.value,
      public: isPublic.value,
      is_template: isTemplate.value,
      template_type: demoTemplate.value,
      premium_template: isPremium.value,
      type: demoType.value,
      thumbnail_image_custom: getCustomThumbnail(),
      run_on_click: + runOnClick.value
    };
    demoOwner.value = userID.value;
    // Switch view back to original if premium turned off
    if (isPremium.value === false && originalHtml.value !== htmlCode.value) {
      htmlCode.value = originalHtml.value ? originalHtml.value : htmlCode.value ? htmlCode.value : '';
      cssCode.value = originalCss.value ? originalCss.value : cssCode.value ? cssCode.value : '';
      jsCode.value = originalJs.value ? originalJs.value : jsCode.value ? jsCode.value : '';
    } 
    if (adminTemplateCreate.value) data['default_template'] = isPublic.value ? 'true' : 'false';
    // Expect type to be group id (not event object triggered by saving from settings)
    if (type && typeof(type) !== 'object') data['id_grouping'] = type === 'personal' ? null : type;
    // Admin-only: Override save limitation
    if (override && adminSaveOverride.value) {
      data.override = true;
    }
    if (newOwner && adminSaveOverride.value) {
      data.changeUser = true;
      data.id_user = newOwner;
      demoOwner.value = newOwner;
    }
    if (existing.value) {
      // Update demo
      $api('demo/update', {
        slug: uid.value,
        data,
      }, $global)
      .then(response => {
        existing.value = true;
        let messageStatus = response.data.warningCount > 0 ? 'warning' : response.data.result ;
        let messageText = messageStatus === 'success' ? 'Demo updated!' : response.data.warning;
        $message({
          message: messageText,
          showClose: true,
          type: messageStatus,
        });

        saveTags(override && adminSaveOverride.value);
        settingsVisible.value = dirty.value = false;
        forkReady.value = true;
        if (promptForkVisible.value) promptForkVisible.value = false;

        // cb
        if (cb && typeof cb === 'function') cb();
      })
      .catch(error => {
        // 401 error prompts user to login to save demo
        if (error && error.response && error.response.status === 401) {
          $message({
            duration: 0,
            showClose: true,
            message: 'Please log in or sign up to save demo.',
            type: 'error',
          });
          triggerLogin();
        } else if (error && error.response && error.response.status === 403) {
          // Prompt user to fork demo because demo does not belong to user and user cannot override save restrictions
          promptForkVisible.value = true;
        } else {
          // Unable to update
          $message({
            duration: 0,
            message: 'Could not update the page',
            showClose: true,
            type: 'error',
          });
          // Fork to save demo if user does not own demo
          forkVisible.value = true;
        }
      });
    } else {
      // Fork over metadata
      $api('metadata/read', {
        uid: cachedDemo.value.uid,
        format: 'grid',
      }, $global).then((result) => {
        // Update only if there was a cached demo
        if (cachedDemo.value && cachedDemo.value.uid) {
          data.metadata = metadataToCopy(result.data);
        };

        // Fork demo
        $api('demo/add', {
          data
        }, $global)
        .then((response) => {
          existing.value = true;
          if (displayMessage) {
            let messageStatus = response.data.warningCount > 0 ? 'warning' : response.data.result ;
            let messageText = messageStatus === 'success' ? 'Demo created!' : response.data.warning;
            $message({
              message: messageText,
              showClose: true,
              type: messageStatus,
            });
          }
          // Reload the page with the new uid
          isForking.value = true;
          let path = `${createPath.value}${response.data.uid}`;
          if ($route.path !== path) $router.push({path});
          uid.value = response.data.uid;
          dirty.value = false;
          saveTags();
          settingsVisible.value = false;
          saveWarningVisible.value = false;
          isForking.value = false;
          forkReady.value = true;

          // Cb
          if (cb && typeof cb === 'function') cb();
        })
        .catch((error) => {
          triggerLogin();
        });
      });
    }
    // set a visual timeout to enable the save button because the server takes 10+ seconds to run the 
    // necessary code to save all assets through cloud function rendering
    window.clearTimeout(saveButtonTimeout.value);
    saveButtonTimeout.value = setTimeout(() => {
      currentlySaving.value = false;
    }, 10000);
    createPreview();
  };

  // On save 401 error, prompt user to login or signup
  function triggerLogin() {
    // Trigger lock and save locally
    let lockButton = document.querySelector('[lockbutton]');
    saveLocally();
    if (lockButton) lockButton.click();
  };

  function saveTags(override) {
    //S ort through all of the tags. If any are null, then we need to save them.
    //TODO: Batch save tags
    let tagsToSave = tags.value.filter((tag) => tag.id === null);
    let tagsToRemove = [];
    // Attempt to save tags
    tagsToSave.forEach((tag, index) => {
      // Special case for "zc-gallery" tag. Requires `chartType` and `vanityUrl` metadata to be added.
      if (tag.name === 'zc-gallery') {
        let chartType = demoMetadata.value.find(m => m.metadata === 'chartType');
        let vanityUrl = demoMetadata.value.find(m => m.metadata === 'vanityUrl');
        if (!chartType || !vanityUrl) {
          // Remove tag later
          tagsToRemove.push(index);
          // Alert user of reason tag not added
          $message({
            duration: 0,
            message: 'Could not create "zc-gallery" tag. Must set "Chart Type" and "Vanity Url" metadatas.',
            showClose: true,
            type: 'error',
          })
          return;
        };
      };
      // Add tag
      $api('tag/add', {
        uid: uid.value,
        name: tag.name,
        override
      }, $global)
      .then((tagId) => {
        // Remove tag when successfully added
        tags.value.forEach((t) => {
          if (t.id === null && t.name === tag.name) {
            t.id = tagId.data;
          }
        })
      })
      .catch(() => {
        $message({
          duration: 0,
          message: 'Could not create tag',
          showClose: true,
          type: 'error',
        })
      });
    });
    // Remove tags on client-side
    if (tagsToRemove.length > 0) {
      for (let i = tagsToRemove.length-1; i > -1; i--) {
        tags.value.splice(tagsToRemove[i], 1);
      };
    };
  };

  // UPLOAD DIALOG
  function handleUploadClose(done) {
    done();
  };

  /**
   * @description Opens assets and sets correct dropdown value
   */
  function openAssets() {
    updateDemoType();
    assetsVisible.value = true;
  };

  /** 
   * @description Setup assets by filtering into correct dropdown lists and
   * placing currently used as value in dropdown
   */
  function setupAssets() {
    axios({
      url: '/api/asset',
      method: 'get',
      headers: {
        'Authorization': `Bearer ${$store.state.auth.idToken}`,
      },
      json: true,
    })
    .then((result) => {
      const resultAssets = result.data.main;
      resultAssets.forEach(asset => {
        assets.value.push(asset);
        if (asset.name.includes('ZingGrid')) assets_zinggrid.value[asset.id] = asset;
        else assets_zingchart.value[asset.id] = asset;
      });

      let addZingChartAssets = (n) => {
        let index = Object.keys(assets_zingchart.value)
          .filter((val) => {
            return assets_zingchart.value[val].name === `ZingChart${n ? ` ${n}` : ''}`;
        });
        let ref = assets_zingchart.value[index[0]];
        ref.modules = result.data[`zingchart${n}`].versions;
        ref.versions = Object.keys(result.data[`zingchart${n}`].versions);
      };
      addZingChartAssets(2);
      addZingChartAssets(3);
    });
  };

  /**
   * @description Update assets dropdown and demo type
   */
  function updateDemoType() {
    let typeZinggrid = false;
    let typeZingchart = false;
    // Update assets dropdown values to select
    assets.value.forEach(asset => {
      if (htmlCode.value.includes(asset.path)) {
        if (asset.name.includes('ZingGrid')) {
          assetsForm.value.zinggrid = asset.id;
          typeZinggrid = true;
        }
        else if (asset.name.includes('ZingChart')) {
          assetsForm.value.zingchart = asset.id;
          typeZingchart = true;
        }
      }
    });
    // Upate asset dropdown to deselect
    if (!typeZinggrid) assetsForm.value.zinggrid = 'None';
    if (!typeZingchart) assetsForm.value.zingchart = 'None';

    // Check if variant of library scripted used (not listed in assets)
    if (zcScriptRegex.value.test(htmlCode.value)) typeZingchart = true;
    if (zgScriptRegex.value.test(htmlCode.value)) typeZinggrid = true;

    // Update demo type
    if (typeZingchart && !typeZinggrid) demoType.value = 'zingchart';
    else if (!typeZingchart && typeZinggrid) demoType.value = 'zinggrid';
    else if (!typeZingchart && !typeZinggrid) demoType.value = '';
    else demoType.value = 'zingchart,zinggrid';
  };

  /**
   * @description Updates demo with selected assets
   */
  function updateAsset() {
    if (assetsForm.value.zinggrid !== '' && assetsForm.value.zinggrid !== 'ZingGrid' && assetsForm.value.zinggrid !== 'None') {
      fetchAsset('zinggrid', assetsForm.value.zinggrid);
    } else if (assetsForm.value.zinggrid === 'None') {
      removeAsset('zinggrid');
    }
    if (assetsForm.value.zingchart !== '' && assetsForm.value.zingchart !== 'None') {
      fetchAsset('zingchart', assetsForm.value.zingchart);
    } else if (assetsForm.value.zingchart === 'None') {
      removeAsset('zingchart');
    }

    assetsVisible.value = false;
  };

  /**
   * @description Fetches asset path
   * @param {Strint} assetType - type of asset (ex. zinggrid, zingchart, etc)
   * @param {String} assetId - id of asset, used to fetch asset path
   */
  function fetchAsset(assetType, assetId) {
    let regexLib = null;
    // Get existing asset type to replace
    if (assetType === 'zinggrid') {
      regexLib = /\<script src=\"http.*\:\/\/.*.zinggrid.com\/.*zinggrid.*.min.js\"( |defer|crossorigin=\"anonymous\")*\>\<\/script\>/gi;
    } else {
      regexLib = /\<script src=\"http(s)*\:\/\/(cdn.zingchart.com\/zingchart|(app(-stage)*.zingsoft.com|localhost\:8090)\/api\/asset\/zingdata)(\/modules\/.*)*.*(.min)*.js(\?version\=.*)*\"( |defer|crossorigin=\"anonymous\")*\>\<\/script\>/gsi;
    }
    // Replace current demo asset
    let insertLib = null;
    // Include version (if specified)
    let assetRef = assetType === 'zinggrid' ? assets_zinggrid.value[assetId] : assets_zingchart.value[assetId];
    let version = assetType === 'zingchart' && assetsForm.value.zingchartVersion !== 'Latest'
      ? `?version=${assetsForm.value.zingchartVersion}`: '';
    insertLib = `<script src="${assetRef.path}${version}">\<\/script>`;
    // Include module (if specified)
    if (assetType === 'zingchart' && assetsForm.value.zingchartModule !== 'None') {
      let type = assetRef.name.replace('ZingChart ', '');
      insertLib += `\n	  <script src="${window.location.origin}/api/asset/zingdata/${type}/modules/${assetsForm.value.zingchartModule}${version}">\<\/script>`;
    };
    if (regexLib.test(htmlCode.value)) {
      htmlCode.value = htmlCode.value.replace(regexLib, insertLib);
    } else {
      // Insert asset if none to replace
      let headEndTagIndex = htmlCode.value.indexOf('</head>');
      htmlCode.value = htmlCode.value.slice(0, headEndTagIndex) + `  ${insertLib}\n  ` + htmlCode.value.slice(headEndTagIndex);
    }

    instance.refs.codeHTML.updateEditorCode(htmlCode.value);
    nextTick(_ => {createPreview(null, true)});
  };

  /**
   * @description Sets version and module to default values when ZingChart asset updated
   */
  function clearZingChartAsset() {
    assetsForm.value.zingchartVersion = 'Latest';
    assetsForm.value.zingchartModule = 'None';
  };

  /**
   * @description Removes asset from demo
   * @param {Strint} assetType - type of asset (ex. zinggrid, zingchart, etc)
   */
  function removeAsset(assetType) {
    let regexLib = null;
    if (assetType === 'zinggrid') regexLib = /[\s\n]*\<script src=\"http.*\:\/\/.*.zinggrid.com\/.*zinggrid.*.min.js\" defer\>\<\/script\>[\n]*/gi;
    else regexLib = /[\s\n]*\<script src=\"http(s)*\:\/\/(cdn.zingchart.com\/zingchart|(app(-stage)*.zingsoft.com|localhost\:8090)\/api\/asset\/zingdata)(\/modules\/.*)*.*(.min)*.js(\?version\=.*)*\"( |defer|crossorigin=\"anonymous\")*\>\<\/script\>[\n]*/gsi;

    if (regexLib.test(htmlCode.value)) {
      htmlCode.value = htmlCode.value.replace(regexLib, '\n');
      instance.refs.codeHTML.updateEditorCode(htmlCode.value);
      nextTick(_ => {createPreview()});
    };
  };

  /**
   * @description Displays icon on save dropdown to indicate if demo is saved as personal or a group demo
   * @param { String } type - if 'personal', demo is saved as personal demo. Else, all other options indicate demo belongs to group
   */
  function labelDemo(type) {
    let ICON_PERSONAL = '<svg aria-hidden="true" data-prefix="fas" data-icon="save" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="svg-inline--fa fa-save fa-w-14 fa-1x"><path fill="currentColor" d="M433.941 129.941l-83.882-83.882A48 48 0 0 0 316.118 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V163.882a48 48 0 0 0-14.059-33.941zM224 416c-35.346 0-64-28.654-64-64 0-35.346 28.654-64 64-64s64 28.654 64 64c0 35.346-28.654 64-64 64zm96-304.52V212c0 6.627-5.373 12-12 12H76c-6.627 0-12-5.373-12-12V108c0-6.627 5.373-12 12-12h228.52c3.183 0 6.235 1.264 8.485 3.515l3.48 3.48A11.996 11.996 0 0 1 320 111.48z" class=""></path></svg>';
    let ICON_GROUP = '<svg aria-hidden="true" data-prefix="fas" data-icon="users" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="svg-inline--fa fa-users fa-w-20 fa-1x"><path fill="currentColor" d="M96 224c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm448 0c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm32 32h-64c-17.6 0-33.5 7.1-45.1 18.6 40.3 22.1 68.9 62 75.1 109.4h66c17.7 0 32-14.3 32-32v-32c0-35.3-28.7-64-64-64zm-256 0c61.9 0 112-50.1 112-112S381.9 32 320 32 208 82.1 208 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C179.6 288 128 339.6 128 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zm-223.7-13.4C161.5 263.1 145.6 256 128 256H64c-35.3 0-64 28.7-64 64v32c0 17.7 14.3 32 32 32h65.9c6.3-47.4 34.9-87.3 75.2-109.4z" class=""></path></svg>';

    if ($saveIconWrapper.value) $saveIconWrapper.value.innerHTML = type === 'personal' ? ICON_PERSONAL : ICON_GROUP;
  };

  /**
   * @description Navigate to route saved in 'saveWarningNext'. Must set 'saveWarningVisible' false to close
   * dialog when navigating to url with same path and reload demo.
   */ 
  function leavePage() {
    saveWarningVisible.value = false;
    saveWarningNext.value();
    if ($route.params && $route.params.uid) {
      // Reset some variables before loading new demo
      dirty.value = false;
      uid.value = $route.params.uid;
      isForking.value = ('fork' in $route.query);
      forkReady.value = !('fork' in $route.query);
      loadDemo();
    }
  };

  /**
   * @description Update selectedTemplate template and setup demo
   * @param { Object } template - selected template
   */
  function setupTemplate(template) {
    selectedTemplate.value = template;
    setDemo();
  };


  // Update Demo Owner
  /**
   * @description When selecting option to save demo to another user, fetch
   * list of users to display.
   */
  function fetchUserOptions() {
    axios({
      url: `/api/user/list`,
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${$store.state.auth.idToken}`,
      },
    })
    .then((result) => {
      userOptions.value = result.data.result;
    });
  };

  /**
   * @description Triggered when save option selected. When selecting:
   * - 'updateOwner': display prompt to choose who to save demo to
   * - other: save demo under current user or selected group
   */
  function selectSaveOption(option) {
    if (option === 'updateOwner') {
      updateOwnerVisible.value = true;
      
      if (userOptions.value.length === 0) fetchUserOptions();
    } else {
      saveDemo(option);
    }
  };

  function updateDemoData(prop, val) {
    switch (prop) {
      case 'autoComplete': autoComplete.value = val; break;
      case 'autoUpdate': autoUpdate.value = val; break;
      case 'demoTemplate': demoTemplate.value = val; break;
      case 'description': demoDescription.value = val; break;
      case 'docsTooltip': docsTooltip.value = val; break;
      case 'files': files.value = val; break;
      case 'isPremium': isPremium.value = val; break;
      case 'isPublic': isPublic.value = val; break;
      case 'isTemplate': isTemplate.value = val; break;
      case 'metadata': demoMetadata.value = val; break;
      case 'runOnClick': runOnClick.value = val; break;
      case 'tags': tags.value = val; break;
      case 'title': demoTitle.value = val; break;
    }
  };

  /**
   * @description After selecting an owner, update demo owner specified by `newOwnerId.value`
   */
  function updateDemoOwner() {
    if (adminSaveOverride.value) {
      updateOwnerVisible.value = false;
      saveDemo(null, null, true, newOwnerId.value);
      newOwnerId.value = null;
    }
  };

  /**
   * @description Creates label for user based on if the name and email
   * is available
   */
  function userLabel(userInfo) {
    let label = `#${userInfo.id}`;
    if (userInfo.name && userInfo.email) label += `-  ${userInfo.name} (${userInfo.email})`;
    else if (userInfo.name || userInfo.email) label += ` - ${userInfo.name || userInfo.email}`;
    return label;
  };

  /**
   * @description On LayoutsDropdown selection, update `layout` to set active dropdown options then update editor layout
   * @param {String} option - option selected
   * @param {String} type - editor settings type being updated (ex. fontFamily, fontSize, layout, theme, )
   */
  function updateEditorSettings(option, type) {
    // Update `editorSetting`
    editorSetting.value = option;
    switch(type) {
      case 'fontFamily':
        // Update editor fontFamily
        editorFontFamily.value = option;
        break;
      case 'fontSize':
        // Update editor fontSize
        editorFontSize.value = option;
        break;
      case 'layout': 
        // Update editor layout 
        let rowLayout = ['left', 'right'];
        let generalLayout = rowLayout.indexOf(editorSetting.value) > -1 ? 'col' : 'row';
        editorLayout.value = `${generalLayout}-${editorSetting.value}`;
        // Trigger resize on preview
        resizePreview();
        break;
      case 'theme':
        // Update editor theme
        editorTheme.value = editorSetting.value;
        break;
    }
  };

  function toggleSidebar() {
    showSidebar.value = 'show';
  };
</script>

<style>
  :root {
    --code__header-height: 25px;
  }

  .action-text { text-transform: uppercase; }

  /* ASSETS */
  [dialog="assets"] .el-select {
    width: 100%;
  }
  [dialog="assets"] .el-input-icon {
    top: 0;
  }

  .content {
    width: 100%;
  }

  .dialog_container {
    display: flex;
    margin: 1.5rem 0;
  }
  
  .editor {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .editor__top {
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;
    min-height: 300px;
    min-width: 0;
    overflow: hidden;
  }

  .editor__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 40px;
    max-height: 40px;
    padding: 0.5rem 0;
    font-size: 1rem;
  }

  .editor__controls--left {
    display: flex;
    align-items: center;
    flex: 1;
  }

  .editor__controls--left .el-input {
    font-size: 1rem;
    padding-right: 2rem;
  }

  .editor__controls--right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .editor__controls-toggle {
    display: flex;
    background: var(--color-tertiary-1);
    color: #FFF;
    border-radius: 10px;
    padding: 0.25rem 1rem;
    width: 90px;
    margin-right: 1rem;
  }

  .editor__controls ~ .flex {
    height: calc(100% - 40px);
  }

  .editor__code-container {
    display: flex;
    height: 100%;
    z-index: 200;
  }

  .editor__code {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    width: 100%;
  }

  .editor__code-header {
    display: flex;
    justify-content: space-between;
    background: var(--color-primary-4);
    font-size: 0.8rem;
    color: #FFF;
    padding: 0.2rem 0.5rem;
    user-select: none;
    min-height: var(--code__header-height);
    max-height: var(--code__header-height);
  }

  .editor__maximize-icon {
    cursor: pointer;
  }

  .editor__bottom {
    background: #e6e6e6;
    display: flex;
    flex-direction: column;
  }
  .editor__bottom-controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: var(--color-primary-4);
    width: 100%;
    padding: 1rem;

  }

  .editor__bottom__runOnClick .container {
    align-items: center;
    background:#e6e6e6;
    display: flex;
    height: 100%;
    justify-content: center;
  }

  .editor__bottom__runOnClick .clickToRun {
    align-items: center;
    display: flex;
    flex-direction: column;
   }

  .editor__bottom__runOnClick .clickToRun:hover {
    cursor: pointer;
  }

  .editor__bottom__runOnClick .clickToRun p {
    color: #063747;
    font-size: 1.5rem;
  }

  .editor__bottom__runOnClick .clickToRun:hover p {
    color: #606266;
  }

  .editor__bottom__runOnClick .clickToRun svg path {
    fill: #063747;
  }

  .editor__bottom__runOnClick .clickToRun:hover svg path {
    fill: #606266;
  }

  .editor__codewrap {
    height: calc(100% - 25px);
    font-size: 1rem;
  }

  .el-message--error {
    z-index: 10000000 !important;
  }
  .el-message .el-message__closeBtn {
    top: 6px;
  };

  [forkDemo] .el-button { 
    float: inherit;
    width: 100%;
  }

  [forkDemo] .el-dialog__body {
    padding: 0px 45px 30px;
  }

  /* Create Demo Dialog Overwrites */
  .template__dialog .el-dialog {
    padding-right: 0 !important;
  }

  .template__dialog .el-dialog__header {
    padding: 2rem 2.3rem 0 !important;
  }

  .template__dialog .el-tabs__header {
    margin: 0;
    padding: 0 0 0 2.3rem;
  }

  .template__dialog .el-tabs__active-bar {
    height: 4px;
  }

  .template__dialog .el-tabs__content {
    background: #F4F4F4;
  }

  .template__dialog .el-tabs__item {
    font-weight: 400;
    height: 38px;
  }

  .template__dialog .el-tabs__nav-wrap::after {
    background-color: transparent;
  }

  .template__dialog .el-tabs--top {
    font-size: 0.875rem;
  }

  .el-dialog__premium .el-dialog__body {
    padding: 0 45px 30px !important;
  }

  .el-dialog__premium .el-button {
    float: none !important;
    margin-top: 0.75rem;
  }

  .preview{
    height: 100%;
    flex: 1;
    width: 100%;
    border: 0;
  }

  [short].el-dialog{
    padding: 0 3rem 0 0 !important;
    width: 30rem !important;
  }

  .upload-dialog .el-dialog{
    width: 70% !important;
  }

  .upload-dialog__files {
    margin: 0;
    padding: 0;
    max-height: 300px;
    overflow-y: scroll;
  }
  .upload-dialog .el-dialog__header {
    padding: 1rem;
    border-bottom: 1px solid #EFEFEF;
  }

  .upload-dialog .el-dialog__title {
    font-size: 1.2rem;
    color: #303133;
    font-weight: 600;
  }

  .upload-dialog__heading {
    padding: 1rem 0;
    margin: 0;
  }

  .upload-dialog .el-dialog__body {
    padding: 0 1rem 1rem 1rem;
  }

  .upload-dialog__file {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
    padding: 0.5em;
    border-bottom: 1px solid #EFEFEF;
  }

  .collapsed_bar {
    height: 100%;
    width: 20px;
  }


  .editor__alert {
    position: absolute;
    z-index: 1100;
  }


  .editor__title {
    width: 100%;
    max-width: 500px;
    font-size: 1.25rem;
    font-weight: 300;
    border: 0;
    background: none;
    margin-right: 1rem;
  }

  .editor__title:focus{
    border-bottom: 1px solid var(--color-tertiary-1);
  }

  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
    text-align: center;
  }

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}


/* TEMPLATE DIALOG */
.template__dialog {
  width: 100%;
}

.template__dialog .el-dialog {
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 800px;
  height: 70%;
  max-height: 600px;
  flex: 1;
  overflow: hidden;
}

.template__dialog .el-dialog__body {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  overflow: hidden;
  height: 100%;
}

.template__container {
  display : flex;
  padding: 0.5rem 1rem 1rem;
  width: 100%;
  height: 100%;
  min-height: 0;
}

.el-tabs__content {
  display: flex;
  height: 100%;
}

.template__container .el-tabs {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.template__container .el-tab-pane {
  display: flex;
  flex: 1;
}

.settings_dialog .el-tab-pane {
  display: flex;
  flex-direction: column;
}

.template__container,
.template__dialog .el-dialog__body {
  padding: 0;
}

.template__button.el-button {
  border-radius: var(--border-radius);
  margin-bottom: 0.25rem;
  margin: auto 0 0.25rem 0;
  width: 4.5rem;
}

.template__description {
  color: #303133;
  font-size: 0.75rem;
  line-height: 1rem;
  margin: 0.35rem 0;
}

.template__list {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  padding: 1.15rem;
  width: 100%;
}

.template__item {
  background: #fff;
  border-radius: 8px;
  display: flex;
  flex: 1 0 25%;
  flex-direction: column;
  margin: 0.75rem;
  padding: 0.75rem 1.25rem;
  width: 40%;
}

.template__item:last-child {
  max-height: 15.5rem;
}

.template__item:first-child,
.template__item:nth-child(2) {
  flex: 1 0 40%;
}

.template__title {
  color: #303133;
  font-size: 0.75rem;
  font-weight: bolder;
  margin: 0 0 0.35rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: calc(100% - 1px);
}

.template__image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  height: 100%;
  width: 100%;
}

.template__image__wrapper {
  background: #E6E6E6;
  border-radius: 8px;
  height: 5.25rem;
  padding: 0.75rem;
  width: 100%;
}

.template__item:first-child > .template__image__wrapper,
.template__item:nth-child(2) > .template__image__wrapper,
.template__item:last-child > .template__image__wrapper {
  height: 8.25rem;
}

.settings__dialog .el-dialog {
  display: flex;
  flex-direction: column;
  min-height: 400px;
  width: 80%;
  max-width: var(--max-width);
}

.settings__dialog .el-tab-pane {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-left: 1.5rem;
  min-height: 400px;
  flex: 1;
}

.settings__dialog .el-input, .settings__dialog .el-textarea {
  max-width: 400px;
}

.settings__dialog .el-tab-pane .el-button{
  max-height: 45px;
}

.settings__entry {
  margin-bottom: 1.5rem;
}

/* TAGS */
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  vertical-align: bottom;
}
.input-new-tag:not(:first-child) {
  margin-left: 10px;
}
.input-new-tag input,
.input-new-tag .el-input__icon {
  line-height: 32px !important;
  height: 32px !important;
}

.demo-description textarea {
  min-height: 70px !important;
}

@media (min-width: 420px) {
  .editor__controls {
    padding: 0.8rem;
  }

  .editor__controls--right {
    justify-content: space-between;
  }

  .editor__controls--right .el-checkbox-group, .editor__controls--right .el-button {
    margin-left: 10px;
  }

  .editor__top {
    min-height: 0;
  }
}

@media (max-width: 360px) {
  .editor__controls--right .el-button-group .el-button--mini,
  .editor__controls--right .el-radio-button--mini .el-radio-button__inner,
  .editor__controls--right .el-dropdown .el-dropdown__icon {
    font-size: 9px;
  }
}

@media (max-width: 420px) {
  .editor__controls--right {
    justify-content: space-evenly;
    width: 100%;
  }

  /* El button mini override */
  .el-button, 
  .el-button-group .el-button--mini,
  .el-radio-button--mini .el-radio-button__inner {
    padding: 5px 10px !important;
  }

  .template__item,
  .template__item:first-child,
  .template__item:nth-child(2) {
    flex: 1 0 40%;
    width: 100%;
  }
}

@media (max-width: 770px) {
  .action-text {
    display: none;
  }
}

.iframe-mask {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
}
</style>
